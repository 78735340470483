//当前模块：API统一管理
import requests from './requests'
import store from '@/plugins/store'
// store.state.iam.corp
const appendToken = function(para){
    para.corp = store.state.iam.corp
    para.userId = store.state.iam.userId
    para.token2 = store.state.iam.token2
    para.userName = store.state.iam.userName
    return para
}

const appendTokenForm = function(para){
    para.append("corp",store.state.iam.corp)
    para.append("userId",store.state.iam.userId)
    para.append("token2",store.state.iam.token2)
    para.append("userName",store.state.iam.userName)
    return para
}

export const reqOnAuthenticate = (data) =>{
    return requests({url:'/vue/onAuthenticate',method:'post',data})
}

export const reqOnAuthorize = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onAuthorize',method:'post',data})
}

export const reqOnQueryGetApp = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onQuery/getApp',method:'post',data})
}

export const reqOnConfigAddSGA = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/addSGA',method:'post',data})
}

export const reqOnConfigRemoveSGA = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/removeSGA',method:'post',data})
}
export const reqOnConfigAddAGA = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/addAGA',method:'post',data})
}

export const reqOnConfigRemoveAGA = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/removeAGA',method:'post',data})
}


export const reqOnConfigUpdateSGS = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/updateSGS',method:'post',data})
}

export const reqOnConfigUpdateAGS = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onConfig/updateAGS',method:'post',data})
}

export const reqOnBLOB = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onBLOB',method:'post',data,responseType:'blob'})
}

export const reqOnJSON = (para) =>{
    let data = appendToken(para)
    return requests({url:'/vue/onJSON',method:'post',data})
}

export const reqOnFORM = (para) =>{
    let data = appendTokenForm(para)
    return requests({url:'/vue/onFORM',method:'post',data})
}