import { reqOnJSON,reqOnBLOB,reqOnFORM} from '@/api'
import { setErr,getProfile,getToken } from '@/utils'

const state = {
    incidentId:"",
    incidentIds:[],

    incidentAccept:[],
    incidentQuery:[],

    //by Detail Page
    incident: {},
    incidentAttachment: [],
    incidentHistory: [],
    incidentGroup: [],
    incidentRole : {
        role: null, //报修人，主要接单人，次要接单人，管理员
        canComment: false,
        canAccept: false,
        canRoute: false,
        canClose: false,
        canEvaluate: false,
        canCancel:false
      },
    attachmentLibrary:{}, //{attachmentName,attachmentUrl}
    attachmentPreview:[],
    authorization : {
        statusId : ["待处理","处理中","已处理","已评价",'已取消'],
        roleId : ["报修人","接单人","主要接单人","次要接单人","管理员"],
        canId : ["canComment","canAccept","canRoute","canClose","canEvaluate","canCancel"],
        canRule : [
           [
               [true,false,false,false,false],//报修人
               [true,true,false,false,false],//接单人
               [true,true,false,false,false],//主要接单人
               [true,false,false,false,false],//次要接单人
               [true,false,false,false,false]//管理员
           ], //待处理
           [
               [true,false,false,false,false],//报修人
               [true,false,true,true,false],//接单人
               [true,false,true,false,false],//主要接单人
               [true,false,false,false,false],//次要接单人
               [true,false,true,false,false]//管理员
           ],//处理中
           [
               [true,false,false,false,true],//报修人
               [true,false,false,false,false],//接单人
               [true,false,false,false,false],//主要接单人
               [true,false,false,false,false],//次要接单人
               [true,false,false,false,false]//管理员
           ],//已完成
           [
               [true,false,false,false,false],//报修人
               [true,false,false,false,false],//接单人
               [true,false,false,false,false],//主要接单人
               [true,false,false,false,false],//次要接单人
               [true,false,false,false,false]//管理员
           ],//已评价
           [
            [true,false,false,false,true],//报修人
            [true,false,false,false,false],//接单人
            [true,false,false,false,false],//主要接单人
            [true,false,false,false,false],//次要接单人
            [true,false,false,false,false]//管理员
           ],//已取消
       ]
     },
    appServiceGroup:[]
    

}
const mutations = {
    SET_ID(state, incidentId) {
        state.incidentId = incidentId
    },   
    SET_IDS(state,incidentIds){
        state.incidentIds = incidentIds
    },

    async SET_DETAIL(state, value) {
        
        let attachmentPreview = []

        //Incident
        value.incident.description = value.incident.description.replace(/\r\n/g,'<br>')
        value.incident.description = value.incident.description.replace(/\n/g,'<br>')
        if(value.incident.solution)
        {
            value.incident.solution = value.incident.solution.replace(/\r\n/g,'<br>')
            value.incident.solution = value.incident.solution.replace(/\n/g,'<br>')
        }
        if(value.incident.feedback)
        {
            value.incident.feedback = value.incident.feedback.replace(/\r\n/g,'<br>')
            value.incident.feedback = value.incident.feedback.replace(/\n/g,'<br>')
        }


        switch(value.incident.statusId){
            case 1:
                value.incident.type="warning"
                break
            case 2:
                value.incident.type="warning"
                break
            case 3:
                value.incident.type="success"
                break
            case 4:
                value.incident.type="primary"
                break
        }
        //Incident Attachment
        for(let attachment of value.incidentAttachment)
        {
          
            if(!state.attachmentLibrary.hasOwnProperty(attachment.attachmentName))
            {
                //下载文件
                
                let URL = await reqOnBLOB({grp:"db",api:"downloadAttachment",incidentId:state.incidentId,attachmentName:attachment.attachmentName})
                // URL = "http://127.0.0.1:5000/static/img/a.png"
                state.attachmentLibrary[attachment.attachmentName] = URL
                attachment.attachmentUrl = URL
                attachmentPreview.push(URL)
                
            }
            else
            {
                attachment.attachmentUrl = state.attachmentLibrary[attachment.attachmentName]
                attachmentPreview.push(state.attachmentLibrary[attachment.attachmentName])
            }

        }
        //Incident History
        for (let history of value.incidentHistory)
        {
            history.attachment = []
            for (let attachment of value.incidentAttachment)
            {
                
                if(history.seqId == attachment.seqId)
                {
                    history.showAttachment = true
                    history.attachment.push(state.attachmentLibrary[attachment.attachmentName])
                }
            }
            history.historyContent = history.historyContent.replace(/\r\n/g,'<br>')
            history.historyContent = history.historyContent.replace(/\n/g,'<br>')
            //状态判断
            switch(history.statusId){
                case 0:
                    history.type="primary"
                    history.icon = "el-icon-info"
                    history.showMessage = true
                    break
                case 1:
                    history.type="warning"
                    history.icon = "el-icon-question"
                    history.showContent = true
                    if(history.historyType=="建单")
                        history.showDescription = true
                    break
                case 2:
                    history.type="warning"
                    history.icon = "el-icon-s-custom"
                    break
                case 3:
                    history.type="success"
                    history.icon = "el-icon-success"
                    history.showSolution = true
                    break
                case 4:
                    history.type="primary"
                    history.icon = "el-icon-star-on"
                    history.showFeedback = true
                    break
                default:
                    history.type="info"
                    break
            }
            
            
  
        }
        //Incident Role
        let incidentRole = {}
        let {userProfile,serviceProfile,adminProfile} = getProfile()
        let token = getToken()
        let role = ""

        if (token.userId === value.incident.requestor_userId && token.corp === value.incident.requestor_corp) {
             role = '报修人';
             } 
        else if(token.userId === value.incident.worker_userId && token.corp === value.incident.worker_corp){
            role = '接单人';
        }
        else if (serviceProfile.isService >= 1) {
               if (serviceProfile.groupService.some(item => item.groupId === value.incident.groupId)) {
                 role = '主要接单人';
               } 
               else if (serviceProfile.appService.some(item => item.appId === value.incident.appId)) {
                 role = '次要接单人';
               }
         
             } 
        else if (th.adminProfile.isAdmin === 1) {
               if (adminProfile.appAdmin.some(item => item.appId === value.incident.appId)) {
                 role = '管理员';
               }
             }
        else if (adminProfile.isAdmin === 2){
                  role = '管理员';
             } 
        incidentRole.role = role
         if(role !=='')
         {
             incidentRole.canComment = state.authorization.canRule[state.authorization.statusId.indexOf(value.incident.statusName)][state.authorization.roleId.indexOf(role)][state.authorization.canId.indexOf("canComment")]
             incidentRole.canAccept = state.authorization.canRule[state.authorization.statusId.indexOf(value.incident.statusName)][state.authorization.roleId.indexOf(role)][state.authorization.canId.indexOf("canAccept")];
             incidentRole.canRoute = state.authorization.canRule[state.authorization.statusId.indexOf(value.incident.statusName)][state.authorization.roleId.indexOf(role)][state.authorization.canId.indexOf("canRoute")];
             incidentRole.canClose = state.authorization.canRule[state.authorization.statusId.indexOf(value.incident.statusName)][state.authorization.roleId.indexOf(role)][state.authorization.canId.indexOf("canClose")];
             incidentRole.canEvaluate = state.authorization.canRule[state.authorization.statusId.indexOf(value.incident.statusName)][state.authorization.roleId.indexOf(role)][state.authorization.canId.indexOf("canEvaluate")]; 
             //工单状态为待处理/处理中 而且为报修人  显示
            incidentRole.canCancel = ['待处理','处理中'].includes(value.incident.statusName) && role === '报修人' && value.incident.requestor_userId == token.userId
         }
         else{
             incidentRole.canComment = false
             incidentRole.canAccept = false
             incidentRole.canRoute = false
             incidentRole.canClose = false
             incidentRole.canEvaluate = false
             incidentRole.canCancel = false
         }
         if(token.userId =='28010906' && token.corp =='kcc'){
             incidentRole.canComment = true
             incidentRole.canAccept = true
             incidentRole.canRoute = true
             incidentRole.canClose = true
             incidentRole.canEvaluate = true
             incidentRole.canCancel = true
         }


        state.incident = value.incident
        state.incidentAttachment = value.incidentAttachment
        state.incidentHistory = value.incidentHistory
        state.incidentGroup = value.incidentGroup
        state.attachmentPreview = attachmentPreview
        state.incidentRole = incidentRole
        

    },
    
    SET_ACCEPT(state,value){
        for (let incident of value.incidentAccept)
        {
            incident.description = incident.description.replace(/\r\n/g,'<br>')
            incident.description = incident.description.replace(/\n/g,'<br>')
        }
        state.incidentAccept = value.incidentAccept
    },
    SET_QUERY(state,value){
        for (let incident of value.incidentQuery)
        {
            incident.description = incident.description.replace(/\r\n/g,'<br>')
            incident.description = incident.description.replace(/\n/g,'<br>')
            if(incident.solution)
            {
                incident.solution = incident.solution.replace(/\r\n/g,'<br>')
                incident.solution = incident.solution.replace(/\n/g,'<br>')
            }
            if(incident.feedback)
            {
                incident.feedback = incident.feedback.replace(/\r\n/g,'<br>')
                incident.feedback = incident.feedback.replace(/\n/g,'<br>')
            }
            switch(incident.statusId){
                case 1:
                    incident.type="warning"
                    break
                case 2:
                    incident.type="warning"
                    break
                case 3:
                    incident.type="success"
                    break
                case 4:
                    incident.type="primary"
                    break
            }

        }
        state.incidentQuery = value.incidentQuery
    },
    SET_APPSERVICEGROUP(state,value){
        state.appServiceGroup=value.appServiceGroup
    }


}
const actions = {
    async onIncidentAction(context,value){


        value.append("grp","indt")
        let api = value.get("api")

        let result = await reqOnFORM(value)
        
        if (result.errCode == 0) {
            if (api =='create'){
                context.commit('SET_ID', result.incidentId)
                context.commit('SET_IDS', [result.incidentId])
            }
            return true
        }
        else {
            setErr(result, 'indt.onIncidentAction')
            return false
        }
    },
    async onIncidentQuery(context,value){
        value["grp"]= "indt"
        let api=value["api"]
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            if (api=="detail")
                context.commit('SET_DETAIL', result)
            if (api=="accept")
                context.commit('SET_ACCEPT', result)
            if (api=="requestor")
                context.commit('SET_QUERY', result)
            if (api=="worker")
                context.commit('SET_QUERY', result)
            if (api=="workergroup")
                context.commit('SET_QUERY', result)
            if (api=="appServiceGroup")
                context.commit('SET_APPSERVICEGROUP', result)
            return true
        }
        else {
            setErr(result, 'rept.onIncidentQuery')
            return false
        }
    }
}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}
