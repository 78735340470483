<template>
  <div class="containerUserSGA">
    <div class="select">
      <el-descriptions title="管理小组人员分配" :column="1" border :colon="false">
         <el-descriptions-item label="管理小组">
            <el-select v-model="groupId" placeholder="请选择">
              <el-option
                v-for="item in appAG"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              >
              </el-option>
            </el-select>
         </el-descriptions-item>
          <el-descriptions-item label="人员分配">
                  <el-transfer
                  v-model="target"
                  :data="source"
                  filterable
                  :filter-method="filterMethod"
                  filter-placeholder="工号|姓名|部门|职位"
                  :titles = "titles"
                ></el-transfer>
          </el-descriptions-item>
      </el-descriptions>



    </div>
  
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "confAGA",
  data() {
    return {
      groupId: "",
      titles:['管理人员列表','在编人员列表']
    };
  },
  computed: {
    // ...mapState("iam", ["userProfile", "serviceProfile", "adminProfile"]),
    ...mapState("app", [
      "appAG",
      "appAGA",
      "appAdmin",
    ]),
    source() {
      let arr = [];
      for (let e of this.appAdmin) {
        arr.push({
          key: `${e.corp}-${e.userId}`,
          label: `${e.corp}|${e.userName}`,
          userId: e.userId,
          userName: e.userName,
          deptName: e.deptName,
          position: e.position,
        });
      }
      return arr;
    },
    target: {
      get() {
        let arr = [];
        for (let e of this.appAGA) {
          if (e.groupId == this.groupId) {
            arr.push(`${e.corp}-${e.userId}`);
          }
        }
        return arr;
      },
      async set(value) {
        //Add
        for (let v of value) {
          let corp = v.split("-")[0];
          let userId = v.split("-")[1];
          let isFind = false;
          for (let e of this.appAGA) {
            if (
              e.groupId == this.groupId &&
              e.corp == corp &&
              e.userId == userId
            ) {
              isFind = true;
            }
          }
          if (!isFind) {
            let isAdded = await this.onAddAGA({
              addGroupId: this.groupId,
              addCorp: corp,
              addUserId: userId,
            });
            if (!isAdded) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "更新用户组时发生异常",
                  type: "warning",
                  route: "/console/confaga",
                }
              });
            }
          }
        }
        //remove
        for (let e of this.appAGA) {
          if (e.groupId!==this.groupId) continue;

          let corp = e.corp
          let userId = e.userId;
          let isFind = false;
          for (let v of value) {
            if (
              v.split('-')[0] == corp &&
              v.split('-')[1] == userId
            ) {
              isFind = true;
            }
          }
          if (!isFind) {
            let isRemoved = await this.onRemoveAGA({
              removeGroupId: this.groupId,
              removeCorp: corp,
              removeUserId: userId,
            });
            if (!isRemoved) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "更新用户组时发生异常",
                  type: "warning",
                  route: "/console/confaga",
                }
              });
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["onReadState", "onAddAGA","onRemoveAGA"]),
    filterMethod(query, item) {
      return (
        item.userId.indexOf(query) > -1 ||
        item.userName.indexOf(query) > -1 ||
        item.deptName.indexOf(query) > -1 ||
        item.position.indexOf(query) > -1
      );
    },
  },
  async mounted() {
    // if (!this.appAG) {}
      let isReadState = await this.onReadState({});
      if (!isReadState) {
        this.$router.replace({
          name: "error",
          query: {
            title: "获取配置信息时发生异常",
            type: "warning",
            route: "/console/confaga",
          },
        });
      }
    

    this.groupId = this.appAG[0].groupId;
  },
};
</script>

<style scoped lang="less">
.containerUserSGA {
  
  .select {
    width:80%;
    margin:50px auto;
  }

}
</style>