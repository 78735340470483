<template>
  <div class="containerHeader">
    <img src="@/assets/images/kone.png" />

    <span class="title">AskIT 管理平台</span>

    <div class="userinfo">
      <div v-if="!corp">
        <el-avatar icon="el-icon-user-solid" class="avator"> </el-avatar>
      </div>
      <div v-if="corp">
       
       
        <span class="text">{{ position }}-{{adminProfile.isAdmin}}</span>
        <el-divider direction="vertical"></el-divider>

        <span class="text">{{ userName }}</span>
        <el-divider direction="vertical"></el-divider>

        <el-link type="primary" class="text" @click="logout">退出登录</el-link>
         <el-divider direction="vertical"></el-divider>
         <el-avatar :src="avator" class="avator"> </el-avatar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { getConfig } from "@/utils";
export default {
  name: "Header",
  
  data() {
    return {
      logoUrl: "@/assets/images/kone.png",
    };
  },
  computed: {
    ...mapState("iam", ["userName", "corp", "avator", "position","adminProfile"]),
  },
  methods:{
    ...mapMutations("iam", ["REMOVE_TOKEN"]),
    logout(){
      this.REMOVE_TOKEN();
      this.$router.replace({
        name:'login'
      })
    },
  },
};
</script>

<style scoped lang='less'>
.containerHeader {
  height: 60px;
  display: flex;
  color: #409eff;
  text-shadow: 5px 2px 6px rgb(160, 207, 255);
  font-family: 微软雅黑;

  img {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
    margin-left: 10px;
  }
  .title {
    width: 70%;
    text-align: center;
    font-size: x-large;
  }
  .userinfo {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    float: right;
    .text {
      text-align: center;
      margin-right: 10px;
      font-size: medium;
    }
    .avator {
      margin-right: 10px;
    }
  }
}

.grid-content {
  height: 50px;
  color: #409eff;
  text-shadow: 5px 2px 6px rgb(160, 207, 255);
  font-family: 微软雅黑;
  position: relative;
  .title {
    font-size: x-large;
    margin: auto;
    // border: 1px red solid;
  }
  .text {
    font-size: medium;
    margin: auto;
    // border: 1px red solid;
  }

  .avator {
    align-self: center;
    // border: 1px red solid;
  }
}
</style>