import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//引入小仓库
import iam from './iam'
import app from './app'
import rept from './rept'
import indt from './indt'
import util from './util'

//对外暴露store类的实例
export default new Vuex.Store({
    modules:{
        iam,
        app,
        rept,
        indt,
        util
    }
})