<template>
  <div class="containerConsole">
    <div class="navigator">
      <div class="top">
        <div class="logowrapper">
          <img  class="logo" v-show="show_kcc" src="@/assets/images/kone.png" />
          <img  class="logo" v-show="!show_kcc" src="@/assets/images/giantkone.png" />
        </div>
        <div class="line"></div>

      </div>


      <div class="middle">
        <div class="switch" v-show="false">
          <el-switch v-model="isCollapse" active-text="" inactive-text="">
          </el-switch>
        </div>
        <div class="menu">
          <el-menu
            default-active="1-4-1"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            @select="handleSelect"
            :collapse="!isCollapse"
            collapse-transition
            unique-opened
          >
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span slot="title">工单管理</span>
              </template>
              <el-submenu index="1-1">
                <template slot="title">故障报修</template>
                <el-menu-item index="1-1-1" :disabled="disable111">建单</el-menu-item>
                <el-menu-item index="1-1-2" :disabled="disable112">接单</el-menu-item>
                <el-menu-item index="1-1-3" :disabled="disable113">查询</el-menu-item>
                <el-menu-item index="1-1-X" :disabled="disable111">明细</el-menu-item>

              </el-submenu>

            </el-submenu>
            <el-submenu index="2" >
              <template slot="title">
                <i class="el-icon-reading"></i>
                <span slot="title">报表管理</span>
              </template>
              <el-submenu index="2-1">
                <template slot="title">AMS报表</template>
                <el-menu-item index="2-1-1" :disabled="disable211">管理组视图</el-menu-item>
                <el-menu-item index="2-1-2" :disabled="disable212">支持组视图</el-menu-item>
              </el-submenu>
              <el-submenu index="2-2">
                <template slot="title">IAM报表</template>
                <el-menu-item index="2-2-2" :disabled="disable222">SCIM视图</el-menu-item>
                <el-menu-item index="2-2-1" :disabled="disable221">通力云学院视图</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-submenu index="3" :disabled="disable3">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span slot="title">配置管理</span>
              </template>
              <el-submenu index="3-1">
                <template slot="title">应用管理</template>
                <el-menu-item index="3-1-1" :disabled="disable311">基础数据</el-menu-item>
                <el-menu-item index="3-1-2">支持小组</el-menu-item>
                <el-menu-item index="3-1-3" :disabled="disable313">支持范围</el-menu-item>
                <el-menu-item index="3-1-4" :disabled="disable314">管理小组</el-menu-item>
                <el-menu-item index="3-1-5" :disabled="disable315">管理范围</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-suitcase"></i>
                <span slot="title">自助工具</span>
              </template>
              <el-submenu index="4-1"  :disabled="disable41">
                <template slot="title">企业微信</template>
                <el-menu-item index="4-1-1" >通讯录</el-menu-item>
              </el-submenu>
              <el-submenu index="4-2" :disabled="disable42"  >
                <template slot="title">通力云学院</template>
                <el-menu-item index="4-2-1" :disabled="disable421" >师徒配对</el-menu-item>
              </el-submenu>
            </el-submenu>
          </el-menu>
        </div>

      </div>

      <div class="buttom" @click="logout">
          <i class="el-icon-switch-button"></i>
          <h3>退出登录</h3>
      </div>


    </div>


    <div class="workarea">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>

    <div class="dashboard">
        <div class="userwrapper">
          <div class="useravator">
             <img  class="avator" :src="avator" />

          </div>
          <div class="userinfo">
            <h4>{{position}}</h4>
            <h3>{{userName}}</h3>

            <el-button type="primary" disabled plain @click="handleMyData">个人数据中心</el-button>
          </div>
          <div class="line"></div>
          <div class="admingroup">
            <h4>所属管理小组</h4>
            <ul>
              <li v-for="item in adminProfile.groupAdmin" :key="item.groupId">
                {{item.groupName}}
              </li>
            </ul>
          </div>

          <div class="servicegroup">
             <h4>所属支持小组</h4>
              <ul>
            <li v-for="item in serviceProfile.groupService" :key="item.groupId">
                {{item.groupName}}
              </li>
            </ul>
          </div>

        </div>


    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Console",

  mounted() {
    //渲染菜单
     this.$router.replace({ name: "mydata" });
    //  this.$router.replace({ name: "indtdetail" });
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  props:['route'],
  methods: {
    ...mapActions("iam", ["onAuthorize"]),
     ...mapMutations("iam", ["REMOVE_TOKEN"]),
    logout(){
      this.REMOVE_TOKEN();
      this.$router.replace({
        name:'login'
      })
    },
    handleMyData(){
      this.$router.replace({ name: "mydata" });
      // this.$router.replace({ name: "reptasg" });
    },
    handleOpen(key, keyPath) {
      // console.log("handleOpen", key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log("handleClost", key, keyPath);
    },
    handleSelect(key, keyPath) {
      switch (key) {
        case "1-1-1":
          this.$router.replace({ name: "indtcreate" });
          break;
        case "1-1-2":
          this.$router.replace({ name: "indtaccept" });
          break;
        case "1-1-3":
          this.$router.replace({ name: "indtquery" });
          break;
        case "1-1-X":
          this.$router.replace({ name: "indtdetail" });
          break;
        case "2-1-1":
          this.$router.replace({ name: "reptaag" });
          break;
        case "2-1-2":
          this.$router.replace({ name: "reptasg" });
          break;
        case "2-2-1":
          this.$router.replace({ name: "reptmzc" });
          break;
        case "2-2-2":
          this.$router.replace({ name: "reptscim" });
          break;
        case "3-1-1":
          this.$router.replace({ name: "confapp" });
          break;
        case "3-1-2":
          this.$router.replace({ name: "confsga" });
          break;
        case "3-1-3":
          this.$router.replace({ name: "confsgs" });
          break;
        case "3-1-4":
          this.$router.replace({ name: "confaga" });
          break;
        case "3-1-5":
          this.$router.replace({ name: "confags" });
          break;
        case "4-1-1":
          this.$router.replace({ name: "utiltxt" });
          break;
        case "4-2-1":
          this.$router.replace({ name: "utilcoach" });
          break;
        default:
          // console.log("handleSelect", key, keyPath);
      }
    },
  },
  computed: {
    ...mapState("iam", [
      "userName", 
      'userId',
      "corp", 
      "avator", 
      "position",
      "userProfile",
      "serviceProfile",
      "adminProfile"
    ]),

    show_kcc(){
      if(this.corp==='kcc')
        return true
      else
        return false
    },
    disable111(){
        let flag = false
        return flag
    },
    disable112(){
        let flag = (this.serviceProfile.isService ==0)
        return flag
    },
    disable113(){
        let flag = false
        return flag
    },
    disable221(){
        let flag = (this.adminProfile.isAdmin ==0)
        return flag
    },
    disable222(){
        let flag = (this.adminProfile.isAdmin !=2)
        return flag
    },
    disable211(){
        let flag = (this.adminProfile.isAdmin ==0)
        return flag
    },
    disable212(){
        let flag = (this.serviceProfile.isService ==0)
        return flag
    },
    disable3(){
      let flag = (this.adminProfile.isAdmin != 2 && this.serviceProfile.isService != 2)
      return flag
    },
    disable311(){
      let flag = (this.adminProfile.isAdmin != 2)
      return flag
    },
    disable313(){
      let flag = (this.adminProfile.isAdmin != 2)
      return flag
    },
    disable314(){
      let flag = (this.adminProfile.isAdmin != 2)
      return flag
    },
    disable315(){
      let flag = (this.adminProfile.isAdmin != 2)
      return flag
    },
    disable41(){
      // SG0000012 EIT Common DevOps
      // let flag = true
      // for (let g of this.serviceProfile.groupService)
      // {
      //   if(g["groupId"]==="SG0000012")
      //   {
      //     flag = false
      //     break;
      //   }
      // }
      // return flag

      let flag = (this.adminProfile.isAdmin != 2)
      return flag
    },
    disable42(){
      let flag = (this.adminProfile.isAdmin == 0)
      return flag
    },
    disable421(){
      if (!this.adminProfile){
        return true
      }

      // AG0000004 SSD Admin
      let flag = true
      for (let g of this.adminProfile.groupAdmin)
      {
        if(g["groupId"]==="AG0000004")
        {
          flag = false
          break;
        }
      }
      return flag
      // let flag = (this.adminProfile.isAdmin == 0)
      // return flag
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        if (!this.userProfile || !this.serviceProfile || !this.adminProfile) {
          let isAuthorized = await this.onAuthorize();
          if (!isAuthorized) {
            this.$router.replace({
              name: "error",
              query: {
                title: "授权时发生异常",
                type: "warning",
                route: "/login",
              },
            });
          }else{
            this.$router.replace(this.route)
          }
          
        }
      },
    },
  },
};
</script>

<style scoped lang="less">
.containerConsole {
  // 相对外层容器居中排列
  width : 1600px;
  height: 100%;
  margin: 0 auto;

  //左中右三块布局
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  .line{
    height: 1px;
    width:120px;
    margin: 30px auto;
    background-color: #ebeced;
  }
  .navigator {
    background-color:#ffffff;
    width:200px;
    margin-right: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top{
      .logowrapper{
        width:100px;
        margin:30px auto;
        .logo{
          width:100px;
        }
      }


    }

    .middle{
      flex-grow: 1;


    }
    .buttom{
      display: flex;
      align-items: center;
      margin-bottom:50px;
      height: 40px;
      i{
        margin-left:15px;
        margin-right: 15px;
      }
    }
    .buttom:hover{
      background-color: #ecf5ff;
      cursor: pointer;
      color:red;
    }



  }
  .workarea {
      background-color: #ffffff;
      overflow:auto;
      width: 1000px;
     flex-grow: 1;
    //  -webkit-scrollbar:5px;
    //  -webkit-scrollbar-thumb:
    // -webkit-scrollbar-track:

  }
  .dashboard{
    background-color: #ffffff;
    width: 300px;
    margin-left: 3px;
    overflow: hidden;
    box-shadow: 0 4px 4px rgba(22, 22, 230, 0.6);
    .userwrapper{     
   
      .useravator{
         width:100px;
         margin: 30px auto;
         
        .avator{
          border-radius: 10%;
          width:100px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
        }
      }
      .userinfo{
        width:200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
          font-size: 20px;
          color:#8e9cb2
        }
        h3{
          margin-top:10px;
          margin-bottom: 20px;
          font-size: 26px;
          color:#101332;
          }
      }
      .admingroup{
        width:200px;
        margin:0 auto;
        border-radius: 10px;

        border: 2px solid #e8eff9;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
        padding: 10px;
        h4{
          font-size: 16px;
          color:#8e9cb2
        }
        li{
          font-size:14px;
          margin-top:5px;

        }

      }
      .servicegroup{
        width:200px;
        margin:30px auto;
        border-radius: 10px;
        border: 2px solid #e8eff9;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
        padding: 10px;
        h4{
          font-size: 16px;
          color:#8e9cb2;
        }
        li{
          font-size:14px;
          margin-top:10px;

        }
      }

    }


  }
}
</style>