<template>
  <div class="containerindtQuery">
    <div class="filter">
      <el-descriptions title="查询工单"  :colon="false">
         <template slot="extra">
            <el-button-group>
              <el-button type="primary" icon="el-icon-user" @click="onQueryByRequestor">我创建的工单</el-button>
              <el-button type="success" icon="el-icon-help" @click="onQueryByWorker">我处理的工单</el-button>
              <el-button type="warning" icon="el-icon-headset" :disabled="serviceProfile.isService==0" @click="onQueryByWorkerGroup">我所在支持小组的工单</el-button>
            </el-button-group>
          </template>


      </el-descriptions>
    </div>
    
    <el-divider></el-divider>
    <div class="info">
     <el-table :data="incidentQuery.filter(data=> !searchText||data.incidentId.toLowerCase().includes(searchText.toLowerCase())||data.description.toLowerCase().includes(searchText.toLowerCase()))"
      ref="incidentTable"
      border
      highlight-current-row
      resizable
      stripe
      style="width: 100%"
      >
      <!-- :row-class-name="tableRowClassName" -->
      <el-table-column  width="80" t> 
        <!-- eslint-disable-next-line -->
         <template slot="header" slot-scope="scope">
            <el-tooltip  effect="dark" :content="searchText" placement="top-start" :open-delay="500">
             <el-input v-model="searchText" size="mini" clearable></el-input>
            </el-tooltip>
              
          
      </template>
        <template slot-scope="scope">
           <el-button type="primary" plain icon="el-icon-edit" circle @click="goDetail(scope.row)"></el-button>
        </template>

      </el-table-column>
      <el-table-column prop="incidentId"  sortable label="工单号"></el-table-column>
      <el-table-column 
      label="工单状态"
      prop="statusName"
      :filters="[{ text: '待处理', value: '待处理' }, { text: '处理中', value: '处理中' },{ text: '已处理', value: '已处理' },{ text: '已评价', value: '已评价' },{ text: '已取消', value: '已取消' }]"
      :filter-method="filterStatus">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type">{{scope.row.statusName}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="requestor_userName" sortable label="报修人员"></el-table-column>
      <el-table-column prop="createTime"  sortable label="报修时间"></el-table-column>
      <el-table-column prop="worker_userName" sortable label="接单人员"></el-table-column>
      <el-table-column prop="acceptTime"  sortable label="接单时间"></el-table-column>
      <el-table-column prop="appName"  sortable label="应用名称"></el-table-column>
      <el-table-column prop="groupName" sortable label="支持小组"></el-table-column>
      <el-table-column width='120' label="故障描述">
        <template slot-scope="scope">
           <el-popover
            placement="top-start"
            title="故障描述"
            width="200"
            trigger="hover"
            >
            <div v-html="scope.row.description"></div>
            <el-button slot="reference">故障描述</el-button>
          </el-popover>
       </template>
      </el-table-column>

   

     </el-table>
    </div>



  
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "indtQuery",
  data() {
    return {
      fullscreenLoading:false,
      searchText:"",
    };
  },
  computed: {
    ...mapState("iam", ["serviceProfile"]),
    ...mapState("indt", ["incidentQuery"]),


  },
  methods: {
    ...mapActions("indt", ["onIncidentAction","onIncidentQuery"]),
    ...mapMutations("indt", ["SET_ID","SET_IDS"]),
    tableRowClassName(rowInfo){
      let incident = rowInfo.row
       if (incident.statusId === 1 ||incident.statusId === 2 ) {
          return 'warning-row';
         
        } 
        else if (incident.statusId === 3) {
          return 'success-row';
        }
        else if (incident.statusId === 4) {
          return 'primary-row';
        }
        return '';
    },
    filterStatus(value,row){
       return row.statusName == value
    },
    async goDetail(incident){
            // await this.doAccept(incident)
            // this.onQuery()
            
            let incidentId = incident.incidentId
            let incidentIds=[]
            for(let data of this.$refs.incidentTable.tableData)
            {
              incidentIds.push(data.incidentId)
            }

            this.SET_ID(incidentId)
            this.SET_IDS(incidentIds)

            this.$router.replace({ name: "indtdetail" });
      },


    async onQueryByRequestor(){
            let isQuery = await this.onIncidentQuery({api:"requestor"})
            if (!isQuery) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询待处理工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
      },

    async onQueryByWorker(){
            let isQuery = await this.onIncidentQuery({api:"worker"})
            if (!isQuery) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询待处理工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
      },
    async onQueryByWorkerGroup(){
        let isQuery = await this.onIncidentQuery({api:"workergroup",groupService:this.serviceProfile.groupService})
            if (!isQuery) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询待处理工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
    }
 

  },

  mounted() {
    this.onQueryByRequestor()
  }
};
</script>

<style scoped lang="less">

.containerindtQuery {
 
  .filter{
    width:90%;
    margin:10px 30px;
  }
  .info{
    width:90%;
    margin:10px 30px;
   
 /deep/ .el-table {
     .success-row {
        background: #67C23A
      }
      .primary-row {
       background: #409EFF
      }
      .warning-row {
          background: #E6A23C
      }
    }

      
  }
  .form {
    width:80%;
    margin:50px auto;
  }

  h3{
    margin-top:10px;
    margin-bottom: 20px;
    font-size: 26px;
    color:#101332;
    }

}
</style>