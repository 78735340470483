import { reqOnJSON,reqOnBLOB,reqOnFORM} from '@/api'
import { setErr,getProfile,getToken } from '@/utils'

const state = {
    EWCUserDetail:{},
    MZCPairCoachList:[]
    
}
const mutations = {
    SET_EWCUSERDETAIL(state, value) {
        state.EWCUserDetail = value.userDetail
    },   
    SET_MZCPAIRCOACHLIST(state, value) {
        state.MZCPairCoachList = value.list
    },   



    



}
const actions = {
    async onUtilJson(context,value){

        value["grp"]= "util"
        let api=value["api"]
        //updateEWCMobile
        //queryEWCUser
        //queryMZCPairCoachList

        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            if (api=="queryEWCUser")
                context.commit('SET_EWCUSERDETAIL', result)
            if (api=="queryMZCPairCoachList")
                context.commit('SET_MZCPAIRCOACHLIST', result)
            return true
        }
        else {
            setErr(result, 'onUtilJson')
            return false
        }
    },

    async onUtilForm(context,value){
        value.append("grp","util")
        let api = value.get("api")
        //pairMZCCoach

        let result = await reqOnFORM(value)
        
        if (result.errCode == 0) {
            // if (api =='create'){
            //     context.commit('SET_ID', result.incidentId)
            //     context.commit('SET_IDS', [result.incidentId])
            // }
            return true
        }
        else {
            setErr(result, 'onUtilForm')
            return false
        }
    },
    async DownloadFile(context,value) {
        value["grp"]="s3"
        let api = value["api"]
        let result = await reqOnBLOB(value)
        if (result.errCode == 0) {
            return true
        }
        else {
            setErr(result, 'util.DownloadFile')
            return false
        }

    },
}
const getters = {}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}
