<template>
  <div class="containerUtilTXT">
    <div class="selection">
      <el-descriptions title="通讯录管理工具"  border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain  @click="queryUser">查询用户</el-button>
          </template>
        <el-descriptions-item label="用户ID">
              <el-input
                  type="text"
                  placeholder="8位数字，ex. 28xxxxxx,64xxxxxx"
                  v-model="ewcId"
                  maxlength="8"
                  minlength="8"
                  show-word-limit
                  clearable
                  @input="(val)=> ewcId= val.replace(/[^\d]/g,'')"
                ></el-input>
        </el-descriptions-item>

    </el-descriptions>

    </div>

    <el-divider></el-divider>


 
     <div class="selection">
          <el-descriptions title="明细信息"  border :column="2" :colon="false">
          <template slot="extra">
            <el-button type="warning" plain :disabled="!EWCUserDetail.ewcCorp" @click="updateUserMobile">更新手机号</el-button>
          </template>
            <el-descriptions-item label="corp">{{ EWCUserDetail.ewcCorp}}</el-descriptions-item>
            <el-descriptions-item label="id">{{ EWCUserDetail.ewcId}}</el-descriptions-item>
            <el-descriptions-item label="status">{{ statusDesc}}</el-descriptions-item>
            <el-descriptions-item label="gender">{{ genderDesc}}</el-descriptions-item>
            <el-descriptions-item label="dept">{{ EWCUserDetail.department_name}}</el-descriptions-item>
            <el-descriptions-item label="name">{{ EWCUserDetail.name}}</el-descriptions-item>
            <el-descriptions-item label="position">{{ EWCUserDetail.position}}</el-descriptions-item>
            <el-descriptions-item label="location">{{ EWCUserDetail.location}}</el-descriptions-item>
            <el-descriptions-item label="costcenter">{{ EWCUserDetail.costcenter}}</el-descriptions-item>
            <el-descriptions-item label="profitcenter">{{ EWCUserDetail.profitcenter}}</el-descriptions-item>
            <el-descriptions-item label="upn">{{ EWCUserDetail.upn}}</el-descriptions-item>
            <el-descriptions-item label="networkid">{{ EWCUserDetail.networkid}}</el-descriptions-item>
            <el-descriptions-item label="mobile">{{ EWCUserDetail.mobile}}</el-descriptions-item>
            <el-descriptions-item label="新手机号">
              <el-input
                  :disabled = "!EWCUserDetail.ewcCorp"
                  type="text"
                  placeholder="11位手机号"
                  v-model="ewcMobile"
                  maxlength="11"
                  minlength="11"
                  show-word-limit
                  clearable
                  @input="(val)=> ewcMobile= val.replace(/[^\d]/g,'')"
                ></el-input>
            </el-descriptions-item>

          </el-descriptions>
     </div>
          <!-- userDetail
                {"department_id":"215",
                "department_name":"信息技术部 China IT",
                "name":"康乐",
                "alias":"KANG LE",
                "position":"Area Solution Manager",
                "mobile":"13482043897",
                "gender":"1",
                "email":"KEN.KANG@KONE.COM",
                "avatar":"http://wework.qpic.cn/wwhead/duc2TvpEgST9hicuyypLEKNuEulCed0eQtezTCo0luPtSLT7e33xR0icEbwaBAP65iaVcczCXQHZIw/0",
                "status":1,
                "enable":1,
                "costcenter":"KCCG303",
                "profitcenter":"GD0303",
                "upn":"Ken.Kang@KONE.com",
                "contractstartdate":"","contractenddate":"",
                "jobid":"80002201",
                "jobfamily":"Solution Owner",
                "jobgroup":"Information Technology",
                "jobtitle":"Area Solution Manager",
                "jobcategory":"Staff",
                "location":"Shanghai FL HQ",
                "linemanager":"28000256",
                "deptcategory":"",
                "deptregion":"",
                "deptbranch":"",
                "deptbusiness":"",
                "deptstation":"",
                "networkid":"CHNKEKAN2",
                "ewcId":"28010906",
                "ewcCorp":"kcc"}
           -->


  
  


  

  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "utilTXT",
  data(){
    return{
      fullscreenLoading:false,
      ewcId:"",
      ewcMobile:""



    }
  },
  computed: {
    ...mapState("util", ["EWCUserDetail"]),
    statusDesc(){
      switch(this.EWCUserDetail.status){
        case 1:
           return "已激活"
        case 2:
           return "已禁用"
        case 4:
           return "未激活"
        case 5:
           return "退出企业"
        
      }
    //  激活状态: 1=已激活，2=已禁用，4=未激活，5=退出企业。

    },
    genderDesc(){
       switch(this.EWCUserDetail.gender){
        case '0':
           return "未定义"
        case '1':
           return "男"
        case '2':
           return "女"
        
      }

      // 0表示未定义，1表示男性，2表示女性。
    }
  },
  methods: {
    ...mapActions("util", ["onUtilJson"]),


  async queryUser(){
      this.ewcMobile = ""
      let ewcId = this.ewcId
      var regEWCID = /^(28|64)\d{6}/
      if(regEWCID.test(ewcId))
      {
        let isQueryUser = await this.onUtilJson({api:"queryEWCUser",ewcId});
        if (!isQueryUser) {
          this.$router.replace({
            name: "error",
            query: {
              title: "查询通讯录异常",
              type: "warning",
              route: "/console/utiltxt",
            },
          })
        } 
      }
      else{
        this.$message({
            showClose: true,
            message: '员工号是28或64开头的8位数字，请重新输入！',
            type: 'warning'
          });
      }    
    },
  async updateUserMobile(){
      let ewcId = this.EWCUserDetail.ewcId
      let ewcCorp = this.EWCUserDetail.ewcCorp
      let ewcMobile = this.ewcMobile
      var regEWCMOBILE = /^1[34578]\d{9}/
      if(regEWCMOBILE.test(ewcMobile) && ewcMobile != this.EWCUserDetail.mobile)
      {
        this.ewcId = ewcId
        let isUpdateUser = await this.onUtilJson({api:"updateEWCMobile",ewcId,ewcCorp,ewcMobile});
        if (!isUpdateUser) {
          this.$router.replace({
            name: "error",
            query: {
              title: "更新通讯录异常",
              type: "warning",
              route: "/console/utiltxt",
            },
          })
        }
        else{
          this.$message({
            showClose: true,
            message: '手机号更新成功',
            type: 'success'
          });
          this.queryUser()
        } 
        

      }
      else{
        this.$message({
            showClose: true,
            message: '手机号不符合规范或新手机号与原手机号重复，请重新输入！',
            type: 'warning'
          });
      }   
    }
  },
  
   mounted() {


     
    
  },
};
</script>

<style scoped lang="less">
.containerUtilTXT {
    .line{
    height: 1px;
    width:90%;
    margin: 10px auto;
    background-color: #ebeced;
  }
  .selection{
    width:80%;
    margin:10px auto;
  }
  
  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .statuswrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .status{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .leadtimewrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .leadtime{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .feedbackwrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .feedback{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  .chartwrapper{
    padding:30px;
  }



}
</style>