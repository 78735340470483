import dayjs from 'dayjs'
import config from './config'

const getENV = function (env){
    let ENV = ''
    switch (env) {
        case '{{env}}':
            ENV = 'VUE'
            break;
        case 'loc':
            ENV = 'LOC'
            break;
        case 'dev':
            ENV = 'DEV'
            break;
        case 'prod':
            ENV = 'PROD'
            break;
    }
    return ENV
}

export const getConfig = (env) => {
   let ENV = getENV(env)
   return config[ENV]
}

export const setToken = (value) => {
    localStorage.setItem('corp', value.corp)
    localStorage.setItem('userId', value.userId)
    localStorage.setItem('userName', value.userName)
    localStorage.setItem('deptId', value.deptId)
    localStorage.setItem('deptName', value.deptName)
    localStorage.setItem('position', value.position)
    localStorage.setItem('email', value.email)
    localStorage.setItem('mobile', value.mobile)
    localStorage.setItem('avator', value.avator)
    localStorage.setItem('token2', value.token2)
}
export const getToken = () => {
    return {
        // corp: 'kcc',
        // userId: '28010906',
        // userName: '康乐',
        // token2: 'QODmeNyk1TcstwKM3pVfS8GBA',
        corp: localStorage.getItem('corp'),
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('userName'),
        token2: localStorage.getItem('token2'),
        deptId: localStorage.getItem('deptId'),
        deptName: localStorage.getItem('deptName'),
        position: localStorage.getItem('position'),
        email: localStorage.getItem('email'),
        mobile: localStorage.getItem('mobile'),
        avator: localStorage.getItem('avator'),
    }
}
export const setProfile = (value) => {
    sessionStorage.setItem('userProfile', JSON.stringify(value.userProfile))
    sessionStorage.setItem('serviceProfile', JSON.stringify(value.serviceProfile))
    sessionStorage.setItem('adminProfile', JSON.stringify(value.adminProfile))
   
}
export const getProfile = () => {
    return {
        userProfile: JSON.parse(sessionStorage.getItem('userProfile')),
        serviceProfile: JSON.parse(sessionStorage.getItem('serviceProfile')),
        adminProfile: JSON.parse(sessionStorage.getItem('adminProfile'))
       
    }
}
export const removeToken = () => {
    // localStorage.removeItem("corp")
    // localStorage.removeItem('userId')
    // localStorage.removeItem('userName')
    // localStorage.removeItem('deptId')
    // localStorage.removeItem('deptName')
    // localStorage.removeItem('position')
    // localStorage.removeItem('email')
    // localStorage.removeItem('mobile')
    // localStorage.removeItem('avator')
    localStorage.removeItem('token2')
}


export const setErr = (value, from) => {
    localStorage.setItem('errTime', dayjs().format('YYYY-MM-DD HH:mm:ss'))
    localStorage.setItem("errFrom", from)
    localStorage.setItem("errCode", value.errCode)
    localStorage.setItem("errMessage", value.errMessage)
}

export const getErr = () =>{
    return {
        errTime:localStorage.getItem('errTime'),
        errFrom:localStorage.getItem('errFrom'),
        errCode:localStorage.getItem('errCode'),
        errMessage:localStorage.getItem('errMessage'),
    }
}