<template>
  <div class="containerUserSGA">
    <div class="select">
        <el-descriptions title="应用管理范围" :column="2" border :colon="false">
          <template slot="extra">
            <el-button type="primary" @click="updateAGS">更新小组排位</el-button>
          </template>
          <el-descriptions-item label="公司">
            <el-select placeholder="请选择公司" v-model="corp" @change="getAGS" >
              <el-option key="kcc" label="通力中国" value="kcc"></el-option>
              <el-option key="gk"  label="巨人通力" value="gk"></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="应用">
            <el-select v-model="appId" placeholder="请选择应用" @change="getAGS">
              <el-option
                v-for="item in app"
                :key="item.appId"
                :label="item.appName"
                :value="item.appId"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item span = "2" label="管理小组1">
                  <el-select placeholder="请选择小组1" clearable v-model="adminGroup1" >
          <el-option
          v-for="item in appAG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
          </el-descriptions-item>
          <el-descriptions-item span = "2" label="管理小组2">
                  <el-select placeholder="请选择小组2" clearable  v-model="adminGroup2" >
          <el-option
          v-for="item in appAG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
          </el-descriptions-item>
          <el-descriptions-item span = "2" label="管理小组3">
                  <el-select placeholder="请选择小组3" clearable v-model="adminGroup3" >
          <el-option
          v-for="item in appAG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
          </el-descriptions-item>
          <el-descriptions-item span = "2" label="管理小组4">
                  <el-select placeholder="请选择小组4" clearable v-model="adminGroup4" >
          <el-option
          v-for="item in appAG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
          </el-descriptions-item>
          <el-descriptions-item span = "2" label="管理小组5">
                  <el-select placeholder="请选择小组5" clearable v-model="adminGroup5" >
          <el-option
          v-for="item in appAG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
          </el-descriptions-item>
        </el-descriptions>
    </div>


  
</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "confAGS",
  data() {
    return {
      corp:'kcc',
      appId: "APP0000001",
      adminGroup1:'',
      adminGroup2:'',
      adminGroup3:'',
      adminGroup4:'',
      adminGroup5:'',
    };
  },
  computed: {
    ...mapState("app", [
      "app",
      "appAG",
      "appAGS",
    ]),
  },
  methods: {
    ...mapActions("app", ["onReadState","onUpdateAGS"]),

    async updateAGS(){
      let isUpdated = await this.onUpdateAGS({
        updateCorp:this.corp,
        updateAppId:this.appId,
        adminGroup1:this.adminGroup1,
        adminGroup2:this.adminGroup2,
        adminGroup3:this.adminGroup3,
        adminGroup4:this.adminGroup4,
        adminGroup5:this.adminGroup5,
      });
      if (!isUpdated) {
        this.$router.replace({
          name: "error",
          query: {
            title: "更新支持范围时发生异常",
            type: "warning",
            route: "/console/confags",
          }
        });
      }
      else{
        this.getAGS()
      }  
    },
    async getAGS (){
        let isReadState = await this.onReadState({});
        if (!isReadState) {
          this.$router.replace({
            name: "error",
            query: {
              title: "获取配置信息时发生异常",
              type: "warning",
              route: "/console/confags",
            },
          });
        }
      
        this.adminGroup1 = ''
        this.adminGroup2 = ''
        this.adminGroup3 = ''
        this.adminGroup4 = ''
        this.adminGroup5 = ''

      let corp = this.corp
      let appId = this.appId
      for (let e of this.appAGS) {
            // console.log(e);
            if (e.corp == corp && e.appId == appId) {
              this.adminGroup1 = e.adminGroup1
              this.adminGroup2 = e.adminGroup2
              this.adminGroup3 = e.adminGroup3
              this.adminGroup4 = e.adminGroup4
              this.adminGroup5 = e.adminGroup5
              break;
            }
          }

    }
  },
  async mounted() {
    this.getAGS()
  },
};
</script>

<style scoped lang="less">
.containerUserSGA {

  .select {
        width:80%;
    margin:50px auto;
  }
}
</style>