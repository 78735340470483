import Vue from 'vue'

//完整引入
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en'
//Vue.use(ElementUI,{size:'medium',zIndex:3000})
Vue.use(ElementUI)

//按需引入
// import {Button,Row,DatePicker} from 'element-ui'
// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
// locale.use(lang)
// Vue.prototype.$ELEMENT = {size:'small',zIndex:3000}
// Vue.component(Button.name,Button)
// Vue.component(Row.name,Row)
// Vue.component(DatePicker.name,DatePicker)