<template>
  <div class="containerLogin clearfix">

    <div class="selector-wrapper">
      <h3>欢迎登录AskIT管理平台</h3>
      <div class="selector">
        <el-radio-group v-model="corp_selector">
        <el-radio-button label="通力无限"></el-radio-button>
        <el-radio-button label="巨通视界"></el-radio-button>
      </el-radio-group>
      </div>
      <div id="kccQRCode" v-show="show_kcc"></div>
      <div id="gkQRCode"  v-show="!show_kcc"></div>

    </div>





      <!-- <el-alert
        title="通力无限"
        type="success"
        effect="dark"
        :closable="false">
      </el-alert> -->
      
      <!-- <el-button type="primary" v-if="false" @click="handleClick1">发送Code到服务端</el-button> -->
      <!-- <el-button type="primary" v-if="false" @click="handleClick2">初始进入login</el-button> -->
  </div>
</template>

<script>
import WwLogin from "@/assets/js/wwLogin.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { getConfig } from "@/utils";
export default {
  name: "Login",
  data() {
    return {
      corp_selector: "通力无限"

    };
  },
  //在LOC环境下，只需要更新Storage.token2/corp/userId即可完成login
  computed: {
    ...mapState("iam", ["corp", "userId", "token2"]),
    show_kcc(){
      if(this.corp_selector==='通力无限')
        return true
      else
        return false
    }
 
  },
  methods: {
    ...mapActions("iam", ["onAuthenticate"]),

    // handleClick1($event) {
    //   this.$router.replace({
    //     path: "/login",
    //     query: {
    //       code: this.code,
    //       appid: this.appid,
    //     },
    //   });
    // },
    // handleClick2($event) {
    //   this.$router.replace({
    //     path: "/login",
    //   });
    // },
  },
  mounted() {
    //console.log(encodeURIComponent(window.location.href));

    let config = getConfig(env);
    new WwLogin({
      id: "kccQRCode",
      appid: config.kcc_corp,
      agentid: config.kcc_agent,
      redirect_uri: encodeURIComponent( config.uri),
       //redirect_uri:  config.uri,
      state: "",
      href: "https://ewc-askit.kone.cn/static/css/qrcode.css",
    });

    new WwLogin({
      id: "gkQRCode",
      appid: config.gk_corp,
      agentid: config.gk_agent,
      redirect_uri: encodeURIComponent(config.uri), 
      state: "",
      href: "https://ewc-askit.kone.cn/static/css/qrcode.css",
    });
  },
  watch: {
    $route: {
      //https://ewc-askit.dev.aw.kone.cn/#/login?
      //code=CGb3LhGDcLON7YyZTfr-cVLC9PAXB_stiYKjPC8a8Ec
      //&appid=ww07c2c1f936126304

      // https://ewc-askit.dev.aw.kone.cn/?code=y0_K6tnXt0zI8LqJ6oKiRSMPfX9oxD84XybfhEqPlHg&state=&appid=ww07c2c1f936126304#/login
      immediate: true,
      async handler(route) {
        //this.$router.push('/console/usersga')
        let code = route.query.code;
        let appid = route.query.appid;

        if (code && appid) {
          //redirect from WX with code
          //Code2Session

          let isAuthenticated = await this.onAuthenticate({ code, appid });
          if (!isAuthenticated) {
            this.$router.replace({
              name: "error",
              query: {
                title: "登录时发生异常",
                type: "warning",
                route: "/login",
              },
            });
          } else {
            this.$router.replace("/console");
          }
        } else {
          //normal login
          //check if token already exist
          if (this.corp && this.userId && this.token2) {
            this.$router.replace("/console");
          }
        }
      },
    },
  },
};
</script>

<style scoped lang='less'>
.containerLogin {

  // 相对外层容器居中排列
  width : 600px;
  height: 400px;
  margin: 100px auto;

  // 设置背景和内边距
  background-image: url("~@/assets/images/login-bg.jpg");
  background-size: 1000px;
  // background-image: repeating-linear-gradient(to right ,#e0eaff, #d5e4fd 700px);
  overflow: hidden;
  border-radius: 5%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
  padding: 30px;

  // 让内层wrapper靠右
  display:flex;
  flex-direction: column;
  align-items: flex-end;

  .selector-wrapper{
    color: white;
    padding-top:50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      font-size:20px;
    }
    .selector{
      margin-top:20px;
      // margin-bottom: 50px;
    }

  }



  

  
}
</style>