<template>
  <div class="containerUserSGA">
    <div class="select">
    <el-descriptions title="应用支持范围" :column="2" border :colon="false">
       <template slot="extra">
         <el-button type="primary" @click="updateSGS">更新小组排位</el-button>
       </template>
         <el-descriptions-item label="公司">
                 <el-select placeholder="请选择公司" v-model="corp" @change="getSGS" >
              <el-option key="kcc" label="通力中国" value="kcc"></el-option>
              <el-option key="gk"  label="巨人通力" value="gk"></el-option>
            </el-select>
         </el-descriptions-item>
           <el-descriptions-item label="应用">
                   <el-select v-model="appId" placeholder="请选择应用" @change="getSGS">
                  <el-option
                    v-for="item in app"
                    :key="item.appId"
                    :label="item.appName"
                    :value="item.appId"
                  >
                  </el-option>
                </el-select>
           </el-descriptions-item>
          
      <el-descriptions-item span = "2" label="运维名称">
              <el-select placeholder="请选择运维名称" clearable v-model="serviceId" >
          <el-option
          v-for="item in operationData"
          :key="item.serviceId"
          :label="item.serviceName"
          :value="item.serviceId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>

      <el-descriptions-item span = "2" label="支持小组1">
              <el-select placeholder="请选择小组1" clearable v-model="serviceGroup1" >
          <el-option
          v-for="item in appSG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>
      <el-descriptions-item span = "2" label="支持小组2">
              <el-select placeholder="请选择小组2" clearable  v-model="serviceGroup2" >
          <el-option
          v-for="item in appSG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>
      <el-descriptions-item span = "2" label="支持小组3">
              <el-select placeholder="请选择小组3" clearable v-model="serviceGroup3" >
          <el-option
          v-for="item in appSG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>
      <el-descriptions-item span = "2" label="支持小组4">
              <el-select placeholder="请选择小组4" clearable v-model="serviceGroup4" >
          <el-option
          v-for="item in appSG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>
      <el-descriptions-item span = "2" label="支持小组5">
              <el-select placeholder="请选择小组5" clearable v-model="serviceGroup5" >
          <el-option
          v-for="item in appSG"
          :key="item.groupId"
          :label="item.groupName"
          :value="item.groupId"
        >
        </el-option>
      </el-select>
      </el-descriptions-item>
    </el-descriptions>
    </div>
  
</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "confSGS",
  data() {
    return {
      corp:'kcc',
      appId: "APP0000001",
      serviceId:'',
      serviceGroup1:'',
      serviceGroup2:'',
      serviceGroup3:'',
      serviceGroup4:'',
      serviceGroup5:'',
      // 运维下拉数据
      operationData:[]
    };
  },
  computed: {
    ...mapState("app", [
      "app",
      "appSG",
      "appSGS",
    ]),
    isOnchange({ corp, appId }) {
      //等同于 const { isLace , loading }=this
      return { corp, appId }
    },
  },
  watch: {
    //只要有一个值改变都会触发监听
    isOnchange: {
        handler(newVal) {
          //重置输入框的值
          this.serviceId = ''
          //改变下拉框的值
          const { corp,appId } = newVal
          let result = this.appSGS ? this.appSGS.filter(item => (item.appId == appId && item.corp == corp)) : []
          this.operationData = result
          console.log(result,'result');
        },
        immediate: true
    },
    serviceId: {
        handler(newVal) {
          //改变下拉框的值
          let e = this.appSGS ? this.appSGS.find(item => (item.appId == this.appId && item.corp == this.corp && item.serviceId == newVal)) : []
          console.log(e,'data');
          this.serviceGroup1 = e.serviceGroup1
          this.serviceGroup2 = e.serviceGroup2
          this.serviceGroup3 = e.serviceGroup3
          this.serviceGroup4 = e.serviceGroup4
          this.serviceGroup5 = e.serviceGroup5
        },
        immediate: true
    },
  },
  methods: {
    ...mapActions("app", ["onReadState","onUpdateSGS"]),

    async updateSGS(){
      let isUpdated = await this.onUpdateSGS({
        updateCorp:this.corp,
        updateAppId:this.appId,
        serviceId:this.serviceId,
        serviceGroup1:this.serviceGroup1,
        serviceGroup2:this.serviceGroup2,
        serviceGroup3:this.serviceGroup3,
        serviceGroup4:this.serviceGroup4,
        serviceGroup5:this.serviceGroup5,
      });
      if (!isUpdated) {
        this.$router.replace({
          name: "error",
          query: {
            title: "更新支持范围时发生异常",
            type: "warning",
            route: "/console/confsgs",
          }
        });
      }
      else{
        this.getSGS()
      }  
    },
    async getSGS(){
      let isReadState = await this.onReadState({});
      if (!isReadState) {
        this.$router.replace({
          name: "error",
          query: {
            title: "获取配置信息时发生异常",
            type: "warning",
            route: "/console/confsgs",
          },
        });
      }
      this.serviceGroup1=''
      this.serviceGroup2=''
      this.serviceGroup3=''
      this.serviceGroup4=''
      this.serviceGroup5=''
      this.serviceId=''

      let corp = this.corp
      let appId = this.appId
      let serviceId = this.serviceId
      for (let e of this.appSGS) {
            if (e.corp == corp && e.appId == appId && e.serviceId == serviceId) {
              this.serviceId = e.serviceId
              this.serviceGroup1 = e.serviceGroup1
              this.serviceGroup2 = e.serviceGroup2
              this.serviceGroup3 = e.serviceGroup3
              this.serviceGroup4 = e.serviceGroup4
              this.serviceGroup5 = e.serviceGroup5
              break;
            }
          }
    }
  },
  async mounted() {
    this.getSGS()
  },
};
</script>

<style scoped lang="less">
.containerUserSGA {
  .select {
        width:80%;
    margin:50px auto;

  }
  
}
</style>