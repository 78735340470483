import axios from 'axios'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'


const requests = axios.create({
    // 基础路径
    baseUrl:"http://localhost:8080",
    // 请求超时时间
    time:5000,

})

//请求拦截器：在请求发送之前，检测
requests.interceptors.request.use((config)=>{
    //config是配置对象，有一个属性很重要。header请求头
    nprogress.start()
    return config;
})

// 响应拦截器
requests.interceptors.response.use((res)=>{
    nprogress.done()
    let contentType = res.headers["content-type"]
    if(contentType !=='application/json' ){
        let contentDisposition = res.headers["content-disposition"]
        let fileName = contentDisposition.split("=")[1]

        const blob = new Blob([res.data], {
            type:contentType
        });
        const url = window.URL.createObjectURL(blob);
      
        if(contentType =='image/jpeg' || contentType=='image/png')
        {
          
            return url
        }
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", decodeURI(fileName));
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); 
        window.URL.revokeObjectURL(url); 

        return {errCode:0,errMessage:"fileDownLoad",fileName,contentType}
    }

    else{
        return res.data
    }
},(error)=>{
    nprogress.done()
    return {errCode:99,errMessage:error}
})


export default requests