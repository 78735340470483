<template>
  <div class="containerindtDetail">
   <div class="info">
      <el-descriptions title="工单明细" :column="3" border :colon="false">
         <template slot="extra">
          <el-button-group>
            <el-button type="success" plain @click="onPrev" :disabled="incidentIds.length==1"><i class="el-icon-arrow-left el-icon--left"></i>上一单</el-button>
            <el-button type="success" plain >{{"报修单号"+incidentId}}</el-button>
            <el-button type="success" plain @click="onNext" :disabled="incidentIds.length==1">下一单<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </el-button-group>
          </template>
          <el-descriptions-item label="当前状态">
            <el-tag :type="incident.type" effect="plain" :class="incident.statusName == '已取消' ? 'gray-container' : ''">{{incident.statusName}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="应用名称">
            {{incident.appName}}
          </el-descriptions-item>
          <el-descriptions-item label="处理小组">
            {{incident.groupName}}
          </el-descriptions-item>
          <el-descriptions-item label="报修人员">
            {{incident.requestor_userName}}
          </el-descriptions-item>
          <el-descriptions-item label="所属部门">
            {{incident.deptName}}
          </el-descriptions-item>
          <el-descriptions-item label="岗位描述">
            {{incident.position}}
          </el-descriptions-item>
          <el-descriptions-item label="移动手机">
            {{incident.mobile}}
          </el-descriptions-item>
          <el-descriptions-item label="电子邮箱">
            {{incident.email}}
          </el-descriptions-item>
          <el-descriptions-item label="报修时间">
            {{incident.createTime}}
          </el-descriptions-item>
          <el-descriptions-item label="接单人员">
            {{incident.worker_userName}}
          </el-descriptions-item>
          <el-descriptions-item label="派单时间">
            {{incident.dispatchTime}}
          </el-descriptions-item>
          <el-descriptions-item label="接单时间">
            {{incident.acceptTime}}
          </el-descriptions-item>
          <el-descriptions-item label="应用版本">
            {{incident.applicationVersion}}
          </el-descriptions-item>
          <el-descriptions-item label="手机型号">
            {{incident.mobileModel}}
          </el-descriptions-item>
          <el-descriptions-item label="系统版本号">
            {{incident.mobileOsVersion }}
          </el-descriptions-item>
          <el-descriptions-item label="工单操作" span="3">
              <el-button type="primary" @click="submitAccept" plain v-show="incidentRole.canAccept" >接单</el-button>
              <el-button type="primary" @click="showRouteDialog" plain v-show="incidentRole.canRoute">转单</el-button>
              <el-button type="primary" @click="showCloseDialog" plain v-show="incidentRole.canClose">关闭</el-button>
              <el-button type="primary" @click="showEvaluateDialog" plain v-show="incidentRole.canEvaluate">评价</el-button>
              <el-button type="primary" @click="showCommentDialog" v-show="incidentRole.canComment">留言</el-button>
              <el-button type="primary" @click="submitCancel" v-show="incidentRole.canCancel">取消</el-button>
          </el-descriptions-item>
      </el-descriptions>
   </div>

    <el-divider></el-divider>

   <div class="timeline">
          <el-timeline >
             <el-timeline-item
                  v-for="(activity, index) in incidentHistory"
                  :key="index"
                  :type="activity.type"
                  :timestamp="activity.statusName + ' ' +activity.occurTime "
                  placement="top"
                  size="large">
              <div class="leftStyle">
                  <div>
                     <el-tag effect="plain" style="margin-right:10px">{{activity.role}}</el-tag>
                     <el-tag effect="plain" style="margin-right:10px" type="success">{{activity.userName}}</el-tag>
                     <el-tag effect="plain" style="margin-right:10px" type="warning">{{activity.historyType}}</el-tag>
                     </div>
              </div>
   
              <el-card style="width:50%" v-show="activity.showContent||activity.showMessage||activity.showSolution||activity.showFeedback||activity.showAttachment||activity.showDescription">
                  <div style="margin-bottom:10px;"  v-show="activity.showContent">
                    <el-tag effect="plain"  style="margin-right:10px;" type="info"> {{activity.historyContent}} </el-tag>
                  </div>
                  <div style="margin-bottom:10px;"  v-show="activity.showFeedback">
                    <el-tag style="margin-right:10px;" :type="incident.isResolved?'success':'danger'"> {{incident.isResolved?'已解决':'未解决'}} </el-tag>
                    <el-tag :type="incident.isSatisfied?'success':'danger'">{{incident.isSatisfied?'满意':'不满意'}} </el-tag>
                   </div>
                  <div style="margin-bottom:10px;" v-show="activity.showMessage">
                     <el-popover
                        placement="top-start"
                        title="留言记录"
                        width="600"
                        trigger="hover"
                        >
                        <div v-html="activity.historyContent"></div>
                        <el-button slot="reference">留言记录</el-button>
                      </el-popover>
                  </div>
                  <div style="margin-bottom:10px;" v-show="activity.showDescription">
                      <el-popover
                        placement="top-start"
                        title="故障描述"
                        width="600"
                        trigger="hover"
                        >
                        <div v-html="incident.description"></div>
                        <el-button slot="reference">故障描述</el-button>
                      </el-popover>
                  </div>
                  <div style="margin-bottom:10px;" v-show="activity.showSolution">
                
                    <el-popover
                        placement="top-start"
                        title="解决方案"
                        width="600"
                        trigger="hover"
                        >
                        <div v-html="incident.solution"></div>
                        <el-button slot="reference">解决方案</el-button>
                      </el-popover>
                  </div>
                  <div style="margin-bottom:10px;" v-show="activity.showFeedback">
                
                     <el-popover
                        placement="top-start"
                        title="反馈意见"
                        width="600"
                        trigger="hover"
                       >
                        <div v-html="incident.feedback"></div>
                        <el-button slot="reference">反馈意见</el-button>
                      </el-popover>
                  </div>
                  <div  v-show="activity.showAttachment">
                     <el-image 
                      style="width: 80px; height: 80px;margin-right:10px;margin-bottom:10px; border-radius:5px;"
                      v-for="url in activity.attachment"
                      :key="url" 
                      :src="url"
                      :preview-src-list="attachmentPreview">
                    
                    </el-image>
           

                  </div>
              </el-card>    
 
                </el-timeline-item>
          </el-timeline>
  </div>
   
   
  <el-dialog title="留言" :visible.sync="commentDialogVisible" width="50%">
  <el-form  ref="commentForm" :rules="commentRules" :model="commentForm" label-width="80px">
        <el-form-item label="留言区" prop="message">
              <el-input type="textarea" 
                :autosize = "{minRows : 5}" 
                placeholder="请留言。。。" 
                v-model="commentForm.message">
                </el-input>
        </el-form-item>
        <el-form-item label="上传图片">
              <el-upload
                  ref="upload"
                  action="#"
                  accept="image/jpeg,image/png"
                  :on-change="onChange"
                  :on-remove="onRemove"
                  :on-exceed="onExceed"
                  :auto-upload="false"
                  list-type="picture-card"
                  :show-file-list="true"
                  :limit = "9"
                  multiple
                  >
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多9张</div>
                </el-upload>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitComment" v-loading.fullscreen.lock="fullscreenLoading">发布留言</el-button>
            <el-button type="warning" @click="resetComment">重新填写</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>
  
  <el-dialog title="转单" :visible.sync="routeDialogVisible" width="50%">
  <el-form  ref="routeForm" :rules="routeRules" :model="routeForm" label-width="80px">
        <el-form-item label="选择小组" prop="groupName">
              <el-radio-group v-model="routeForm.groupName" >
                <el-radio  
                v-for="item in incidentGroup" 
                :key="item.groupId"
                :label="item.groupName"></el-radio>
              </el-radio-group>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitRoute" v-loading.fullscreen.lock="fullscreenLoading">转单</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>
   
  <el-dialog title="关单" :visible.sync="closeDialogVisible" width="50%">
  <el-form  ref="closeForm" :rules="closeRules" :model="closeForm" label-width="80px">
        <el-form-item label="解决方案" prop="solution">
              <el-input type="textarea" 
                :autosize = "{minRows : 5}" 
                placeholder="请填写解决方案。。。" 
                v-model="closeForm.solution">
                </el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitClose" v-loading.fullscreen.lock="fullscreenLoading">关单</el-button>
            <el-button type="warning" @click="resetClose">重新填写</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>

  <el-dialog title="评价" :visible.sync="evaluateDialogVisible" width="50%">
  <el-form  ref="evaluateForm" :rules="evaluateRules" :model="evaluateForm" label-width="80px">
        <el-form-item label="反馈意见" prop="feedback">
              <el-input type="textarea" 
                :autosize = "{minRows : 5}" 
                placeholder="请填写反馈意见。。。" 
                v-model="evaluateForm.feedback">
                </el-input>
        </el-form-item>
        <el-form-item label="是否解决" prop="isResolved">
              <el-radio-group v-model="evaluateForm.isResolved" >
                <el-radio  label="解决"></el-radio>
                <el-radio  label="未解决"></el-radio>
              </el-radio-group>
        </el-form-item>
        <el-form-item label="是否满意" prop="isSatisfied">
              <el-radio-group v-model="evaluateForm.isSatisfied" >
                <el-radio  label="满意"></el-radio>
                <el-radio  label="不满意"></el-radio>
              </el-radio-group>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitEvaluate" v-loading.fullscreen.lock="fullscreenLoading">评价</el-button>
            <el-button type="warning" @click="resetEvaluate">重新填写</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>
  
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "indtDetail",
  data() {
    return {
      fullscreenLoading:false,

      commentDialogVisible:false,
      commentFileList:"",
      commentParam:"",
      commentForm:{
        message:""
      },
      commentRules: {
          message: [
            { required: true, message: '请留言', trigger: 'blur' }
          ]
      },

      acceptParam:"",

      routeDialogVisible:false,
      routeParam:"",
      routeForm:{
        groupName:""
      },
      routeRules:{
          groupName: [
            { required: true, message: '请选择小组', trigger: 'blur' }
          ]
      },

      closeDialogVisible:false,
      closeParam:"",
      closeForm:{
        solution:""
      },
      closeRules:{
         solution: [
            { required: true, message: '请填写解决方案', trigger: 'blur' }
          ]
      },

      evaluateDialogVisible:false,
      evaluateParam:"",
      evaluateForm:{
        isResolved:"",
        isSatisfied:"",
        feedback:""
      },
      evaluateRules:{
          isResolved: [
            { required: true, message: '请选择是否解决', trigger: 'blur' }
          ],
          isSatisfied: [
            { required: true, message: '请选择是否满意', trigger: 'blur' }
          ],
          feedback: [
            { required: false, message: '请填写反馈意见', trigger: 'blur' }
          ],
      },


    };
  },
  computed: {
    ...mapState("indt", ["incidentId","incidentIds","incident","incidentAttachment","incidentHistory","incidentGroup","attachmentPreview","incidentRole"]),
    ...mapState("iam",["userId","userName"])
  },
  methods: {
    ...mapActions("indt", ["onIncidentAction","onIncidentQuery"]),
    ...mapMutations("indt", ["SET_ID"]),
      onPrev(){
 
        let currentIndex = this.incidentIds.indexOf(this.incidentId)
        let prevIndex
        if (currentIndex ==0){
          prevIndex = this.incidentIds.length-1
        }
        else
          prevIndex = currentIndex -1
        this.SET_ID(this.incidentIds[prevIndex])
        this.onDetail()
      },
      onNext(){
        let currentIndex = this.incidentIds.indexOf(this.incidentId)
        let nextIndex
        if (currentIndex ==this.incidentIds.length-1){
          nextIndex = 0
        }
        else
          nextIndex = currentIndex + 1
        this.SET_ID(this.incidentIds[nextIndex])
        this.onDetail()
      },

      onChange(file,filelist){
          // this.commentParam.append('files',file.raw,file.name)
          this.commentFileList = filelist
      },
      onRemove(file,filelist){
        // this.commentParam.delete('files')
        // for (let f of filelist) {
        //     this.commentParam.append('files',f.raw,f.name)
        // }
        this.commentFileList = filelist
      },
      onExceed(e){
        this.$message({
          message:'最多上传9张图片',
          type:'warning',
          center:true,
          showClose:true})
      },
      showCommentDialog(){
        this.commentDialogVisible = true
      },
      resetComment(){
        this.$refs['commentForm'].resetFields()
        this.$refs['upload'].clearFiles()
        this.commentParam = new FormData()
        this.commentFileList = ""
      },
      async doComment(){
             this.fullscreenLoading=true

            this.commentParam.append("incidentId",this.incidentId)
            this.commentParam.append("role",this.incidentRole.role)
            this.commentParam.append("historyType",'留言记录')
            this.commentParam.append("historyContent",this.commentForm.message)
            this.commentParam.append("api","comment")

            let isComment = await this.onIncidentAction(this.commentParam)
            if(isComment){
              for (let f of this.commentFileList) {
                      this.commentParam = new FormData()
                      this.commentParam.append("api","attachment")
                      this.commentParam.append("incidentId",this.incidentId)
                      this.commentParam.append('files',f.raw,f.name)
                      let isAttachment = await this.onIncidentAction(this.commentParam)
                      if(!isAttachment)
                      {
                            this.$message({
                            message:'附件未能上传成功'+f.name,
                            type:'warning',
                            // center:true,
                            showClose:true})
                      }
              }
          }
            this.fullscreenLoading=false
            this.commentDialogVisible=false
            if (!isComment) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "发布留言时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'发布留言成功',
                  type:'success',
                  // center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetComment()
            //重新刷新数据
            this.onDetail()
      },
      submitComment(){
          this.$refs['commentForm'].validate((valid) => {
          if (valid) {
            this.doComment()
            return true
           
          } else {
            return false
          }
        })
      },

      resetAccept(){
        this.acceptParam = new FormData()
      },
      async doAccept(){
            this.acceptParam.append("incidentId",this.incidentId)
            this.acceptParam.append("role",this.incidentRole.role)
            this.acceptParam.append("api","accept")

            let isAccept = await this.onIncidentAction(this.acceptParam)
            if (!isAccept) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "接单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'接单成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetAccept()
            //重新刷新数据
            this.onDetail()
      },
      async doCancel(){
            this.acceptParam.append("incidentId",this.incidentId)
            this.acceptParam.append("role",this.incidentRole.role)
            this.acceptParam.append("api","cancel")

            let isCancel = await this.onIncidentAction(this.acceptParam)
            if (!isCancel) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "取消时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetAccept()
            //重新刷新数据
            this.onDetail()
      },
      submitAccept(){
        this.$confirm('是否确认接单', '提示', {
          confirmButtonText: '接单',
          cancelButtonText: '让我再想想',
          type: 'warning'
        }).then((res) => {
          this.doAccept()
        }).catch(() => {

        })
      },
      submitCancel(){
        this.$confirm('确认是否取消工单', '取消工单', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.doCancel()
        }).catch(() => {

        })
      },
      showRouteDialog(){
         this.routeDialogVisible = true
      },
      resetRoute(){
        this.$refs['routeForm'].resetFields()
        this.routeParam = new FormData()
      },
      async doRoute(){
            this.fullscreenLoading=true

            let groupName = this.routeForm.groupName
            let groupId
            for (let group of this.incidentGroup) {
                   if(group.groupName==groupName)
                   {
                       groupId = group.groupId
                       break
                   }
              }

            this.routeParam.append("incidentId",this.incidentId)
            this.routeParam.append("role",this.incidentRole.role)
            this.routeParam.append("groupId",groupId)
            this.routeParam.append("groupName",groupName)
            this.routeParam.append("api","route")

            let isRoute = await this.onIncidentAction(this.routeParam)
            this.fullscreenLoading=false
            this.routeDialogVisible=false
            if (!isRoute) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "转单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'转单成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetRoute()
            //重新刷新数据
            this.onDetail()
      },
      submitRoute(){
          this.$refs['routeForm'].validate((valid) => {
          if (valid) {
            this.doRoute()
            return true
           
          } else {
            return false
          }
        })
      },

      showCloseDialog(){
         this.closeDialogVisible = true
      },
      resetClose(){
        this.$refs['closeForm'].resetFields()
        this.closeParam = new FormData()
      },
      async doClose(){
            this.fullscreenLoading=true

            this.closeParam.append("incidentId",this.incidentId)
            this.closeParam.append("role",this.incidentRole.role)
            this.closeParam.append("solution",this.closeForm.solution)
            this.closeParam.append("api","close")

            let isClose = await this.onIncidentAction(this.closeParam)
            this.fullscreenLoading=false
            this.closeDialogVisible=false
            if (!isClose) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "关单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'关单成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetClose()
            //重新刷新数据
            this.onDetail()
      },
      submitClose(){
          this.$refs['closeForm'].validate((valid) => {
          if (valid) {
            this.doClose()
            return true
           
          } else {
            return false
          }
        })
      },

      showEvaluateDialog(){
         this.evaluateDialogVisible = true
      },
      resetEvaluate(){
        this.$refs['evaluateForm'].resetFields()
        this.evaluateParam = new FormData()
      },
      async doEvaluate(){
            this.fullscreenLoading=true

            let isSatisfied = this.evaluateForm.isSatisfied==="满意"?1:0
            let isResolved = this.evaluateForm.isResolved==="解决"?1:0
            this.evaluateParam.append("incidentId",this.incidentId)
            this.evaluateParam.append("role",this.incidentRole.role)
            this.evaluateParam.append("feedback",this.evaluateForm.feedback)
            this.evaluateParam.append("isSatisfied",isSatisfied)
            this.evaluateParam.append("isResolved",isResolved)
            this.evaluateParam.append("api","evaluate")

            let isEvaluate = await this.onIncidentAction(this.evaluateParam)
            this.fullscreenLoading=false
            this.evaluateDialogVisible=false
            if (!isEvaluate) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "评价时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'评价成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.resetEvaluate()
            //重新刷新数据
            this.onDetail()
      },
      submitEvaluate(){
          this.$refs['evaluateForm'].validate((valid) => {
          if (valid) {
            this.doEvaluate()
            return true
           
          } else {
            return false
          }
        })
      },




      async onDetail(){
        if(this.incidentId)
        {
            let isDetail = await this.onIncidentQuery({api:"detail",incidentId:this.incidentId})
            if (!isDetail) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }


        }
        else{
          this.$message({
          message:'请先查询工单，再查看明细',
          type:'warning',
          center:true,
          showClose:true})
          this.$router.replace({ name: "indtquery" });
        }
      }
  },
  mounted() {
      this.commentParam = new FormData()
      this.acceptParam = new FormData()
      this.routeParam = new FormData()
      this.closeParam = new FormData()
      this.evaluateParam = new FormData()
      this.commentFileList = ""
      // this.onDetail()
      // console.log('mount');
  },
  activated(){
     this.onDetail()
  }
};
</script>

<style scoped lang="less">
.containerindtDetail {
  .info{
    width:90%;
    margin:10px 30px;
  }
  .buttons{
    width:90%;
    margin:10px 30px;
  }
  .timeline{
    width:90%;
    position: relative;
    top: 10px;
    left: 250px;
    .leftStyle {
        position: absolute;
        left: -320px;
        top: 1px;
        font-size: 12px;
    }
    .el-timeline-item {
      left: 100px;
    }

  }
  .form {
    width:80%;
    margin:50px auto;
  }

  h3{
    margin-top:10px;
    margin-bottom: 20px;
    font-size: 26px;
    color:#101332;
    }
  .gray-container {
    color:gray
  }

}
</style>