import VueRouter from 'vue-router'
import Vue from 'vue'
import Login from '@/pages/Login'
import Error from '@/pages/Error'
import Console from '@/pages/Console'
import confAPP from '@/pages/confAPP'
import confSGA from '@/pages/confSGA'
import confSGS from '@/pages/confSGS'
import confAGA from '@/pages/confAGA'
import confAGS from '@/pages/confAGS'
import reptASG from '@/pages/reptASG'
import reptAAG from '@/pages/reptAAG'
import reptSCIM from '@/pages/reptSCIM'
import reptMZC from '@/pages/reptMZC'
import indtCreate from '@/pages/indtCreate'
import indtDetail from '@/pages/indtDetail'
import indtAccept from '@/pages/indtAccept'
import indtQuery from '@/pages/indtQuery'
import utilTXT from '@/pages/utilTXT'
import utilCOACH from '@/pages/utilCOACH'
import myData from '@/pages/myData'
Vue.use(VueRouter)

import store from '@/plugins/store'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
            meta: { isLogin: false, title: '登录页面' },
            // beforeEnter:(to,from,next)=>{
            //     console.log('独享',to);
            //     next()
            // },
        },
        {
            name: 'error',
            path: '/error',
            component: Error,
            meta: { isLogin: false, title: '出错页面' },
            props($route) {
                return {
                    title: $route.query.title,
                    type: $route.query.type,
                    route: $route.query.route,
                }
            },
        },
        {
            name: 'console',
            path: '/console',
            component: Console,
            meta: {  isLogin: true,isAuth: false, title: '控制台' },
            props($route) {
                return {
                    route: $route.query.route,
                }
            },
            children: [
                {
                    name: 'confapp',
                    path: 'confapp',
                    component: confAPP,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            //ServiceLevel:2,
                            title: '主数据配置' },
                },
                {
                    name: 'confsga',
                    path: 'confsga',
                    component: confSGA,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            ServiceLevel:2,
                            title: '支持小组配置' },
                },
                {
                    name: 'confsgs',
                    path: 'confsgs',
                    component: confSGS,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            //ServiceLevel:2,
                            title: '支持范围配置' },
                },
                {
                    name: 'confaga',
                    path: 'confaga',
                    component: confAGA,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            // ServiceLevel:2,
                            title: '管理小组配置' },
                },
                {
                    name: 'confags',
                    path: 'confags',
                    component: confAGS,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            // ServiceLevel:2,
                            title: '管理范围配置' },
                },
                {
                    name: 'reptmzc',
                    path: 'reptmzc',
                    component: reptMZC,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:1, 
                            // ServiceLevel:2,
                            title: '米知云报表' },
                },
                {
                    name: 'reptasg',
                    path: 'reptasg',
                    component: reptASG,
                    meta: { isLogin: true,
                            isAuth: true, 
                            // AdminLevel:1, 
                            ServiceLevel:1,
                            title: 'AMS支持报表' },
                },
                {
                    name: 'reptaag',
                    path: 'reptaag',
                    component: reptAAG,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:1, 
                            // ServiceLevel:1,
                            title: 'AMS管理报表' },
                },
                {
                    name: 'reptscim',
                    path: 'reptscim',
                    component: reptSCIM,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            //ServiceLevel:1,
                            title: 'SCIM报表' },
                },
                {
                    name: 'indtcreate',
                    path: 'indtcreate',
                    component: indtCreate,
                    meta: { isLogin: true,
                            isAuth: true, 
                            title: '建单页面' },
                },
                {
                    name: 'indtaccept',
                    path: 'indtaccept',
                    component: indtAccept,
                    meta: { isLogin: true,
                            isAuth: true, 
                            ServiceLevel:1,
                            title: '接单页面' },
                },
                {
                    name: 'indtdetail',
                    path: 'indtdetail',
                    component: indtDetail,
                    meta: { isLogin: true,
                            isAuth: true, 
                            title: '工单明细页面' },
                },
                {
                    name: 'indtquery',
                    path: 'indtquery',
                    component: indtQuery,
                    meta: { isLogin: true,
                            isAuth: true, 
                            title: '工单查询页面' },
                },
                {
                    name: 'utiltxt',
                    path: 'utiltxt',
                    component: utilTXT,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:2, 
                            title: '通讯录管理工具' },
                },
                {
                    name: 'utilcoach',
                    path: 'utilcoach',
                    component: utilCOACH,
                    meta: { isLogin: true,
                            isAuth: true, 
                            AdminLevel:1, 
                            AdminGroup:"AG0000004",//SSD Admin
                            title: '师徒配对工具' },
                },
                {
                    name: 'mydata',
                    path: 'mydata',
                    component: myData,
                    meta: { isLogin: true,
                            isAuth: false, 
                            AdminLevel:0, 
                            // ServiceLevel:2,
                            title: '个人数据中心' },
                }

            ]
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.isLogin) {
        let corp = store.state.iam.corp
        let userId = store.state.iam.userId
        let token2 = store.state.iam.token2
        if (corp && userId && token2) {
            if (to.meta.isAuth) { //判断是否需要鉴权
                let userProfile = store.state.iam.userProfile;
                let serviceProfile = store.state.iam.serviceProfile;
                let adminProfile = store.state.iam.adminProfile
                if (userProfile && serviceProfile && adminProfile) {
                    if(to.meta.AdminLevel&&adminProfile.isAdmin>=to.meta.AdminLevel){
                        if(to.meta.AdminGroup){
                            let groupFound = false
                            for (let g of adminProfile.groupAdmin)
                            {
                                if(g["groupId"]===to.meta.AdminGroup)
                                {
                                    groupFound = true
                                    break
                                }
                            }
                            if(groupFound)
                            {
                                next()
                            }
                            else{
                                next('/console')
                            }
                            
                        }
                        else{
                            next()
                        }  
                    }
                    else if(to.meta.ServiceLevel&&serviceProfile.isService>=to.meta.ServiceLevel){
                        next()
                    }
                    else if(!to.meta.AdminLevel && !to.meta.ServiceLevel)
                    {
                        next()
                    }
                    else{
                        next('/console')
                    }

                    
                }
                else {
                    next({path:'/console',
                    query: {
                        route: to.path  //path
                      }
                    })
                }

            } else {
                next()
            }
        } else {
            next('/login')
        }
    }
    next()
})

//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    document.title = to.meta.title || 'AskIT @企业微信 管理平台'
})

export default router
