<template>
  <div class="containerindtAccept">
    <div class="filter">
      <el-descriptions title="接收工单" :column="2" border :colon="false">
         <template slot="extra">
            <el-button type="primary" icon="el-icon-search" @click="onQuery" :disabled="isAuto"></el-button>

            <el-switch
              v-model="isAuto"
              active-color="#13ce66"
              inactive-color="grey"
              active-text="自动刷新"
              inactive-text="手动刷新"
            >
            </el-switch>

          </template>
          <el-descriptions-item label="选择应用(可多选)">
              <el-select v-model="appIds" multiple  placeholder="请选择" filterable style="width:100%">
              <el-option
                v-for="item in serviceProfile.appService"
                :key="item.appId"
                :label="item.appName"
                :value="item.appId"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="选择小组(可多选)">
            <el-select v-model="groupIds" multiple placeholder="请选择" filterable style="width:100%">
              <el-option
                v-for="item in serviceProfile.groupService"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              >
              </el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="过滤条件" span="2">
            <el-input  placeholder="搜索工单-关键字可以是工单号或故障描述" v-model="filter" clearable width="80%"></el-input>
          </el-descriptions-item>

      </el-descriptions>
    </div>
    
    <el-divider></el-divider>
    <div class="info">
     <el-table :data="incidentList"
      border
      highlight-current-row
      resizable
      stripe
      style="width: 100%"
       @selection-change="handleSelectionChange">
      <el-table-column type="selection"  width="40">    </el-table-column>
            <el-table-column scoped-slot>
        <template slot-scope="scope">
          <el-button type="primary" @click="submitAccept(scope.row)" plain >单笔接单</el-button>
        </template>
        <template slot="header">
          <el-button type="warning" @click="submitAcceptAll" :disabled="incidentSelected.length==0">批量接单</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="incidentId"  sortable label="工单号"></el-table-column>
      <el-table-column prop="requestor_userName" sortable label="报修人员"></el-table-column>
      <el-table-column prop="createTime"  sortable label="报修时间"></el-table-column>
      <el-table-column prop="appName"  sortable label="应用名称"></el-table-column>
      <el-table-column prop="groupName" sortable label="支持小组"></el-table-column>
      <el-table-column label="故障描述">
        <template slot-scope="scope">
           <el-popover
            placement="top-start"
            title="故障描述"
            width="200"
            trigger="hover"
            >
            <div v-html="scope.row.description"></div>
            <el-button slot="reference">故障描述</el-button>
          </el-popover>
       </template>
      </el-table-column>

   

     </el-table>
    </div>



  
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "indtAccept",
  data() {
    return {
      fullscreenLoading:false,
      
      appIds:[],
      groupIds:[],
      filter:"",
      
      isAuto:false,
      timerRefresh:null,
      timerFrequency:5000,

      incidentSelected:[],
     

    };
  },
  computed: {
    ...mapState("iam", ["serviceProfile"]),
    ...mapState("indt", ["incidentId","incidentAccept"]),
    incidentList(){
      let incidentList = [] 

      for(let incident of this.incidentAccept)
      {
          let appFound = false
          let groupFound = false
          let filterFound = false
          
          //按appId查找
          if(this.appIds.length!=0)
          {
            for(let appId of this.appIds)
            {
              if (appId == incident.appId)
              {
                appFound = true
                break
              }
            }
          }
          else{
            appFound = true
          }

          //按groupId查找
          if(this.groupIds.length!=0)
          {
            for(let groupId of this.groupIds)
            {
              if (groupId == incident.groupId)
              {
                groupFound = true
                break
              }
            }
          }
          else{
            groupFound = true
          }

          //filter
          if(this.filter!='')
          {
             if (incident.incidentId.indexOf(this.filter)!=-1 || incident.description.indexOf(this.filter)!=-1)
              filterFound = true
          }
          else{
            filterFound = true
          }

          if (appFound && groupFound && filterFound){
            incidentList.push(incident)
          }
      }
      return incidentList
    },

  },
  methods: {
    ...mapActions("indt", ["onIncidentAction","onIncidentQuery"]),

      handleSelectionChange(rows){
        this.incidentSelected = rows
      },
      submitAcceptAll(){
         this.$confirm('是否批量接收已选择工单，共'+  this.incidentSelected.length +'条', '提示', {
          confirmButtonText: '批量接单('+ this.incidentSelected.length +')',
          cancelButtonText: '让我再想想',
          type: 'warning'
        }).then((res) => {
          this.doAcceptAll()
        }).catch(() => {

        })
      },
      async submitAccept(incident){
            await this.doAccept(incident)
            this.onQuery()
      },
      async doAcceptAll(){
        for (let incident of this.incidentSelected)
        {
          await this.doAccept(incident)
        }
        this.onQuery()
      },
      async doAccept(incident){
            let acceptParam = new FormData()
            acceptParam.append("incidentId",incident.incidentId)
            acceptParam.append("role",'主要接单人')
            acceptParam.append("api","accept")

            let isAccept = await this.onIncidentAction(acceptParam)
            if (!isAccept) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "接单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:incident.incidentId+'接单成功',
                  type:'success',
                  center:true,
                  showClose:true})
                // this.$router.replace({ name: "indtdetail" });
            }


      },
      async onQuery(){
            let isAccept = await this.onIncidentQuery({api:"accept",groupService:this.serviceProfile.groupService})
            if (!isAccept) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询待处理工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
      }
 

  },
  watch:{

				isAuto:{
					immediate:false, //初始化时让handler调用一下
					deep:true,//深度监视
					handler(newValue,oldValue){
						if(newValue)//打开自动刷新
            {
                  this.$message({
                  message:'您已打开接单自动刷新功能',
                  type:'success',
                  center:true,
                  showClose:true})

                  if(!this.timerRefresh)
                      console.log('set timerRefresh')
                      this.timerRefresh = setInterval(() => {
                        this.onQuery()
                      }, this.timerFrequency)
                  }
            else{//关闭自动刷新
                  this.$message({
                  message:'您已关闭接单自动刷新功能',
                  type:'warning',
                  center:true,
                  showClose:true})
                  if(this.timerRefresh)
                  {
                    console.log('clear timerRefresh')
                    clearInterval(this.timerRefresh)
                    this.timerRefresh = null
                  }

            }
					}
				}, 
        incidentAccept:{
          	immediate:false, //初始化时让handler调用一下
            deep:false,//深度监视
            handler(newValue,oldValue){
              for(let n of newValue){
                let found = false
                for(let o of oldValue){
                    if(n.incidentId==o.incidentId)
                    {
                      found = true
                    }
                }
                if(!found)
                {
                  this.$notify({
                    title: '待接收工单提醒',
                    message: '工单号'+n.incidentId+',您所在的支持小组<'+n.groupName+'>有一条来自于应用<'+n.appName+'>的工单',
                    type: 'warning',
                    duration:10000
                  });
                }
              }

            }
        }
  },
  mounted() {
    this.onQuery()
  },
  beforeDestroy(){
      if(this.timerRefresh)
      {
        console.log('clear timerRefresh')
        clearInterval(this.timerRefresh)
        this.timerRefresh = null
      }
  }
};
</script>

<style scoped lang="less">
.containerindtAccept {
  .filter{
    width:90%;
    margin:10px 30px;
  }
    .info{
    width:90%;
    margin:10px 30px;
  }
  .form {
    width:80%;
    margin:50px auto;
  }

  h3{
    margin-top:10px;
    margin-bottom: 20px;
    font-size: 26px;
    color:#101332;
    }

}
</style>