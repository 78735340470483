//iam模块小仓库
import { reqOnJSON } from '@/api'
import { setErr } from '@/utils'

const state = {
    app:[],
    appAG:[],
    appAGA:[],
    appAGS:[],
    appSG:[],
    appSGA:[],
    appSGS:[],
    appService:[], //isService=1,2
    appAdmin:[], //isAdmin =1,2

}
const mutations = {
    SET_APP(state, value) {
        state.app = value.app
        state.appAG = value.appAG
        state.appAGA = value.appAGA
        state.appAGS = value.appAGS
        state.appSG = value.appSG
        state.appSGA = value.appSGA
        state.appSGS = value.appSGS
        state.appService = value.appService
        state.appAdmin = value.appAdmin
    },

    ADD_SGA(state,value){
        // console.log(state,value);
        state.appSGA.push({corp:value.addCorp,userId:value.addUserId,groupId:value.addGroupId})
    },
    REMOVE_SGA(state,value){
        // console.log('RMOVE_SGA',state,value);
        let index=-1
        let corp = value.removeCorp
        let userId = value.removeUserId
        let groupId = value.removeGroupId
        for (let e of state.appSGA){
            index++
            if(corp==e.corp && userId==e.userId && groupId == e.groupId)
                break;
        }
        state.appSGA.splice(index,1)
        //state.appSGA.push({corp:value.addCorp,userId:value.addUserId,groupId:value.addGroupId})
    },
    ADD_AGA(state,value){
        // console.log(state,value);
        state.appAGA.push({corp:value.addCorp,userId:value.addUserId,groupId:value.addGroupId})
    },
    REMOVE_AGA(state,value){
        // console.log('RMOVE_SGA',state,value);
        let index=-1
        let corp = value.removeCorp
        let userId = value.removeUserId
        let groupId = value.removeGroupId
        for (let e of state.appAGA){
            index++
            if(corp==e.corp && userId==e.userId && groupId == e.groupId)
                break;
        }
        state.appAGA.splice(index,1)
        //state.appSGA.push({corp:value.addCorp,userId:value.addUserId,groupId:value.addGroupId})
    },
}
const actions = {
    async onReadState(context,value) {
        value["grp"]="app"
        value["api"]="getApp"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            context.commit('SET_APP', result)
            return true
        }
        else {
            setErr(result, 'app.onReadState')
            return false
        }

    },

    async onAddSGA(context,value){
        value["grp"]="app"
        value["api"]="addSGA"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            context.commit('ADD_SGA', value)
            return true
        }
        else {
            setErr(result, 'app.onAddSGA')
            return false
        }
    },

    async onRemoveSGA(context,value){
        value["grp"]="app"
        value["api"]="removeSGA"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            context.commit('REMOVE_SGA', value)
            return true
        }
        else {
            setErr(result, 'app.onRemoveSGA')
            return false
        }
    },
    async onAddAGA(context,value){
        value["grp"]="app"
        value["api"]="addAGA"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            context.commit('ADD_AGA', value)
            return true
        }
        else {
            setErr(result, 'app.onAddAGA')
            return false
        }
    },

    async onRemoveAGA(context,value){
        value["grp"]="app"
        value["api"]="removeAGA"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            context.commit('REMOVE_AGA', value)
            return true
        }
        else {
            setErr(result, 'app.onRemoveAGA')
            return false
        }
    },

    async onUpdateSGS(context,value){
        value["grp"]="app"
        value["api"]="updateSGS"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            // context.dispatch('onReadState')
            return true
        }
        else {
            setErr(result, 'app.onUpdateSGS')
            return false
        }
    },
    async onUpdateAGS(context,value){
        value["grp"]="app"
        value["api"]="updateAGS"
        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            // context.dispatch('onReadState')
            return true
        }
        else {
            setErr(result, 'app.onUpdateAGS')
            return false
        }
    },

    
   


}
const getters = {

}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}