<template>
  <div class="containerUserSGA">
    <div class="selection">
      <el-descriptions title="通力云学院用户报表" :column="2" border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain :disabled="disableButton" @click="getChartOption">生成图表</el-button>
            <el-button type="primary" plain :disabled="disableButton" @click="downloadFile">下载文件</el-button>
          </template>
        <el-descriptions-item label="公司">
          <el-select v-model="corp" placeholder="请选择公司" @change="getFileList">
            <el-option key="kcc" label="通力电梯有限公司" value="kcc"></el-option>
            <el-option key="gk" label="巨人通力电梯有限公司" value="gk"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="文件日期">
            <el-select v-model="fileName"  placeholder="还未生成报表" >
              <el-option
                v-for="item in listIAM"
                :key="item.fileName"
                :label="item.fileName"
                :value="item.fileName"
              >
              </el-option>
            </el-select>
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <el-divider></el-divider>


    <div class="reportwrapper">
      <div class="userwrapper">
        <h3>当月累计付费用户数</h3>
          <div class="statistic">
            <el-badge type="warning" :value="optionMZC.TOTAL_L">
              <el-tag type="warning">Total</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.SSD_L">
              <el-tag type="success">SSD</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.FSD_L">
              <el-tag type="success">FSD</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.TSG_L">
              <el-tag type="success">TSG</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.EHS_L">
              <el-tag type="success">EHS</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.HR_L">
              <el-tag type="success">HR</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.IT_L">
              <el-tag type="success">IT</el-tag>
            </el-badge>
          </div>
      </div>
      <div class="userwrapper">
        <h3>当前活跃用户数</h3>
        <div class="statistic">
          <el-badge type="warning" :value="optionMZC.TOTAL">
              <el-tag type="warning">Total</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.SSD">
              <el-tag type="success">SSD</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.FSD">
              <el-tag type="success">FSD</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.TSG">
              <el-tag type="success">TSG</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.EHS">
              <el-tag type="success">EHS</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.HR">
              <el-tag type="success">HR</el-tag>
            </el-badge>
            <el-badge type="success" :value="optionMZC.IT">
              <el-tag type="success">IT</el-tag>
            </el-badge>

        </div>
      </div>
      
    </div>

    <div class="chartwrapper">
      <div class="chart" ref="MZC001" id="MZC001" style="height:600px"></div>
      <div class="chart" ref="MZC002" id="MZC002" style="height:600px"></div>

    </div>



  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "reptMZC",
  data(){
    return{
      MZC001:'',
      MZC002:'',

      corp:'kcc',
      applicationId:'APP0000005',
      fileName  : ''
    }
  },
  computed: {
    ...mapState("rept", ["listIAM","optionMZC"]),
    ...mapState("iam", ["adminProfile"]),
    disableButton(){
      return (!this.fileName)
    },
  },
  methods: {
    ...mapActions("rept", ["OnReportOption","OnReportList","OnReportFile"]),


  async getFileList(){

      let isFileList = await this.OnReportList({'type':'iam','appId':this.applicationId,'corpScope':this.corp});
        if (!isFileList) {
          this.$router.replace({
            name: "error",
            query: {
              title: "更新数据清单时异常",
              type: "warning",
              route: "/console/reptmzc",
            },
          })
        }

        if(this.listIAM.length!=0){
          this.fileName = this.listIAM[0].fileName
        }
        else{
          this.fileName = ''
        }
    
      
  },

  async getChartOption(){
      //读对应的报表参数
      if(this.fileName)
      {
          let isChartOption = await this.OnReportOption({'type':'mzc','appId':this.applicationId,'fileName':this.fileName,'corpScope':this.corp});
              if (!isChartOption) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "更新报表参数时异常",
                    type: "warning",
                    route: "/console/reptmzc",
                  },
                })
              }   
            this.MZC001.setOption(this.optionMZC.MZC001)
            this.MZC002.setOption(this.optionMZC.MZC002)

      }
      else
      {
        this.MZC001.setOption({})
        this.MZC002.setOption({})

      }
      
    
    },

  async downloadFile(){
     
            let isFileDownload = await this.OnReportFile({'type':'iam','appId':this.applicationId,'fileName':this.fileName,'corpScope':this.corp});
              if (!isFileDownload) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "下载文件时异常",
                    type: "warning",
                    route: "/console/reptmzc",
                  },
                })
              }   
      
  },
  },

   mounted() {
    this.MZC001 = this.$echarts.init(this.$refs.MZC001)
    this.MZC002 = this.$echarts.init(this.$refs.MZC002)

    
    if (this.adminProfile.appAdmin.length!==0){
        this.appId = this.adminProfile.appAdmin[0].appId
    }
    
    let validUser = false
    for (let app of this.adminProfile.appAdmin){
      if (app.appId === this.applicationId)
      {
        validUser = true 
      }
    }
    if(!validUser){
      this.$router.replace({
        name: "error",
        query: {
          title: "您不是米知云应用的管理员，无权访问该页面",
          type: "warning",
          route: "/console",
        }
      })
    }

    this.getFileList()           
    
  },
};
</script>

<style scoped lang="less">
.containerUserSGA {
  .selection{
    width:80%;
    margin:10px auto;
  }

    .chartwrapper{
    padding:30px;
  }





}

  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .userwrapper{
      // width: 300px;
      padding:10px;
      margin-bottom: 30px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .statistic{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }

  }

  .chartwrapper{
    display: flex;
    flex-direction: column;
    .chart{
      margin-top:50px;
    }
    
  }
</style>