<template>
  <div class="containerReptASG">
    <div class="selection">
      <el-descriptions title="SCIM报表"  border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain  @click="downloadFile">下载文件</el-button>
          </template>
        <el-descriptions-item label="AD小组">
          <el-select v-model="groupName" filterable style="width: 100%" placeholder="请选择AD Group" @change="getOption">
            <el-option
              v-for="item in listADG"
              :key="item.displayName"
              :label="item.displayName"
              :value="item.displayName"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>

    </el-descriptions>

    </div>

    <el-divider></el-divider>
 <el-table
      :data="optionADG.users"
      border
      resizable
      style="width: 100%">
      <el-table-column type="expand">
      <template slot-scope="props">
          <el-descriptions title="明细信息"  border :column="2" :colon="false">
            <el-descriptions-item label="department_id">{{ props.row.department_id }}</el-descriptions-item>
            <el-descriptions-item label="department_name">{{ props.row.department_name }}</el-descriptions-item>
            <el-descriptions-item label="position">{{ props.row.position }}</el-descriptions-item>
            <el-descriptions-item label="email">{{ props.row.email }}</el-descriptions-item>
            <el-descriptions-item label="mobile">{{ props.row.mobile }}</el-descriptions-item>
            <el-descriptions-item label="linemanager">{{ props.row.linemanager }}</el-descriptions-item>
            <el-descriptions-item label="gender">{{ props.row.gender }}</el-descriptions-item>
            <el-descriptions-item label="location">{{ props.row.location }}</el-descriptions-item>
            <el-descriptions-item label="costcenter">{{ props.row.costcenter }}</el-descriptions-item>
            <el-descriptions-item label="profitcenter">{{ props.row.profitcenter }}</el-descriptions-item>
            <el-descriptions-item label="contractstartdate">{{ props.row.contractstartdate }}</el-descriptions-item>
            <el-descriptions-item label="contractenddate">{{ props.row.contractenddate }}</el-descriptions-item>
            <el-descriptions-item label="deptcategory">{{ props.row.deptcategory }}</el-descriptions-item>
            <el-descriptions-item label="deptregion">{{ props.row.deptregion }}</el-descriptions-item>
            <el-descriptions-item label="deptbranch">{{ props.row.deptbranch }}</el-descriptions-item>
            <el-descriptions-item label="deptbusiness">{{ props.row.deptbusiness }}</el-descriptions-item>
            <el-descriptions-item label="deptstation">{{ props.row.deptstation }}</el-descriptions-item>
            <el-descriptions-item label="jobid">{{ props.row.jobid }}</el-descriptions-item>
            <el-descriptions-item label="jobfamily">{{ props.row.jobfamily }}</el-descriptions-item>
            <el-descriptions-item label="jobgroup">{{ props.row.jobgroup }}</el-descriptions-item>
            <el-descriptions-item label="jobtitle">{{ props.row.jobtitle }}</el-descriptions-item>
            <el-descriptions-item label="jobcategory">{{ props.row.jobcategory }}</el-descriptions-item>
          </el-descriptions>





    <!-- ud.avatar, -->


  
      </template>
      </el-table-column>
      <el-table-column prop="userId"  label="userId"></el-table-column>
      <el-table-column prop="userName"  label="userName"></el-table-column>
      <el-table-column prop="name"  label="name"></el-table-column>
      <el-table-column prop="upn"  label="upn"></el-table-column>
      <el-table-column prop="networkid"  label="networkid"></el-table-column>

    </el-table>
  


    <div class="chartwrapper">
      <div ref="SCIM001" id="SCIM001" style="width: 900px;height:400px;"></div>
      <div ref="SCIM002" id="SCIM002" style="width: 10px;height:10px;"></div>
      <div ref="SCIM003" id="SCIM003" style="width: 10px;height:10px;"></div>
    </div>

  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "reptSCIM",
  data(){
    return{
      SCIM001:'',
      SCIM002:'',
      SCIM003:'',
      groupName : ''
    }
  },
  computed: {
    ...mapState("rept", ["listADG","optionADG"]),
  },
  methods: {
    ...mapActions("rept", ["OnReportOption","OnReportList","OnReportViewFile"]),


  async getGroupList(){


      let isGroupList = await this.OnReportList({'type':'adg'});
        if (!isGroupList) {
          this.$router.replace({
            name: "error",
            query: {
              title: "更新数据清单时异常",
              type: "warning",
              route: "/console/reptscim",
            },
          })
        }

      this.groupName = this.listADG[0]["displayName"]

    
      
  },

  async getOption(){
      //读对应的报表参数

          let isChartOption = await this.OnReportOption({'type':'adg','groupName':this.groupName});
              if (!isChartOption) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "更新报表参数时异常",
                    type: "warning",
                    route: "/console/reptscim",
                  },
                })
              }   

      

      
    
    },

  async downloadFile(){

            let isFileDownload = await this.OnReportViewFile({'schemaName':'scim','viewName':'GroupMemberships_v'});
              if (!isFileDownload) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "下载文件时异常",
                    type: "warning",
                    route: "/console/reptscim",
                  },
                })
              }   
      
  },
  },

   mounted() {
    this.SCIM001 = this.$echarts.init(this.$refs.SCIM001)
    this.SCIM002 = this.$echarts.init(this.$refs.SCIM002)
    this.SCIM003 = this.$echarts.init(this.$refs.SCIM003)



    this.getGroupList()      
    this.getOption()

     
    
  },
};
</script>

<style scoped lang="less">
.containerReptASG {
    .line{
    height: 1px;
    width:90%;
    margin: 10px auto;
    background-color: #ebeced;
  }
  .selection{
    width:80%;
    margin:10px auto;
  }
  
  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .statuswrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .status{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .leadtimewrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .leadtime{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .feedbackwrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .feedback{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  .chartwrapper{
    padding:30px;
  }



}
</style>