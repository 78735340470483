//iam模块小仓库
import { reqOnJSON,reqOnBLOB} from '@/api'
import { setErr } from '@/utils'

const state = {
    listIAM:[],
    listAMS:[],
    listADG:[],
    optionASG:{},
    optionAAG:{},
    optionADG:{},
    optionMZC:{}
}
const mutations = {
    SET_LISTIAM(state, value) {
        state.listIAM = value.list
    },   
    SET_LISTAMS(state, value) {
        state.listAMS = value.list
    },  
    SET_LISTADG(state, value) {
        state.listADG = value.list
    }, 
    SET_OPTIONMZC(state, value) {
        state.optionMZC = value.option
    },
    SET_OPTIONASG(state, value) {
        state.optionASG = value.option
    },
    SET_OPTIONAAG(state, value) {
        state.optionAAG = value.option
    },
    SET_OPTIONADG(state, value) {
        state.optionADG = value.option
    }
}
const actions = {
    async OnReportList(context,value) {
        let type = value["type"]
        value["grp"]="report"
        if(type=="ams")
            value["api"]="getAMSList"
        if(type=="amsapp")
            value["api"]="getAMSAPPList"
        if(type=='iam')
            value["api"]="getIAMList"
        if(type=='adg')
            value["api"]="getADGList"

        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            if (type=="ams" || type =='amsapp')
                context.commit('SET_LISTAMS', result)
            if (type=="iam")
                context.commit('SET_LISTIAM', result)
            if (type=="adg")
                context.commit('SET_LISTADG', result)

            return true
        }
        else {
            setErr(result, 'rept.OnReportList')
            return false
        }

    },

    async OnReportOption(context,value) {
        let type = value["type"]
        value["grp"]="report"
        if(type=="asg")
            value["api"]="getASGOption"
        if(type=="aag")
            value["api"]="getAAGOption"
        if(type=='mzc')
            value["api"]="getMZCOption"
        if(type=='adg')
            value["api"]="getADGOption"

        let result = await reqOnJSON(value)
        if (result.errCode == 0) {
            if (type=="asg")
                context.commit('SET_OPTIONASG', result)
            if (type=="aag")
                context.commit('SET_OPTIONAAG', result)
            if (type=="mzc")
                context.commit('SET_OPTIONMZC', result)
            if (type=="adg")
                context.commit('SET_OPTIONADG', result)
            return true
        }
        else {
            setErr(result, 'rept.OnReportOption')
            return false
        }

    },

    async OnReportFile(context,value) {
        let type = value["type"]
        value["grp"]="s3"
        if(type=="ams")
           value["api"]="getAMSFile"
        if(type=="amsapp")
           value["api"]="getAMSAPPFile"
        if(type=="iam")
           value["api"]="getIAMFile"
        let result = await reqOnBLOB(value)
        if (result.errCode == 0) {
            return true
        }
        else {
            setErr(result, 'rept.OnReportFile')
            return false
        }

    },

    async OnReportViewFile(context,value) {
        value["grp"]="db"
        value["api"]="getViewFile"
        let result = await reqOnBLOB(value)
        if (result.errCode == 0) {
            return true
        }
        else {
            setErr(result, 'rept.OnReportViewFile')
            return false
        }

    },

}
const getters = {

}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}