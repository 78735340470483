<template>
  <div class="container clearfix">
    <!-- <div class="header"><Header/></div> -->
    <!-- <div class="main"></div> -->
      <router-view></router-view>

  </div>

</template>

<script>
import Header from '@/components/Header'
export default {
  name: "app",
  components:{Header},
  data() {
    return {
      show: true,
    };
  },
  mounted(){
    this.$router.replace('/login')
  },
};
</script>

<style lang="less">
.container{
  width: 100vw;
  height: 100vh;
  background-color: #ebeced;
  /* height:100%; */
  /* border:1px blue solid; */
}
// h1 {
//   font-size: 40px;
// }
// h2 {
//   font-size: 30px;
// }
// h3{
//   font-size: 20px;
//   font-family: Georgia, 'Times New Roman', Times, serif;
// }
// h4{
//   font-size: 10px;
// }
.clearfix::before,
.clearfix::after{
    content: '';
    display: table;
    clear: both;
}
</style>
