<template>
  <div class="containerMyDashboard">

    <h1>欢迎使用AskIT管理平台</h1>
    <h2></h2>

      

  </div>
</template>

<script>
export default {
  name: "myData",
  mounted(){

  }
};
</script>

<style scoped lang="less">
  .containerMyDashboard {
    // height: 80%;
    // margin:auto auto;
    display: flex;
    // background-image: url("~@/assets/images/welcome.jpg");
    // background-size: cover;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    h1{font-size: 30px;}
    h2{font-size: 20px;}
}
</style>