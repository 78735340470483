<template>
  <div class="containerReptAAG">
    <div class="selection">
      <el-descriptions title="AMS管理小组报表" :column="3" border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain :disabled="disableButton" @click="getChartOption">生成图表</el-button>
            <el-button type="primary" plain :disabled="disableButton" @click="downloadFile">下载文件</el-button>
          </template>
        <el-descriptions-item label="应用名称">
          <el-select v-model="appId"  placeholder="请选择应用" @change="getFileList">
            <el-option
              v-for="item in adminProfile.appAdmin"
              :key="item.appId"
              :label="item.appName"
              :value="item.appId"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="公司">
            <el-select placeholder="请选择公司" v-model="corpScope" @change="getFileList" >
              <el-option key="kcc" label="通力中国" value="kcc"></el-option>
              <el-option key="gk"  label="巨人通力" value="gk"></el-option>
            </el-select>
        </el-descriptions-item>


        <el-descriptions-item label="文件日期">
            <el-select v-model="fileName"  placeholder="还未生成报表" >
              <el-option
                v-for="item in listAMS"
                :key="item.fileName"
                :label="item.fileName"
                :value="item.fileName"
              >
              </el-option>
            </el-select>
        </el-descriptions-item>
    </el-descriptions>

    </div>
    <el-divider></el-divider>

  
    <div class="reportwrapper">
      <div class="statuswrapper">
        <h3>工单状态(#)</h3>
          <div class="status">
            <el-badge :value="optionAAG.CNT1"  type="danger">
              <el-tag type="danger">待处理</el-tag>
            </el-badge>
            <el-badge :value="optionAAG.CNT2"  type="warning">
              <el-tag type="warning">处理中</el-tag>
            </el-badge>
            <el-badge :value="optionAAG.CNT3+optionAAG.CNT4" type="success">
              <el-tag type="success">已完成</el-tag>
            </el-badge>
          </div>
      </div>
      <div class="leadtimewrapper">
        <h3>平均响应时间（小时）</h3>
        <div class="leadtime">
          <el-badge :value="optionAAG.MS1"  type="primary">
              <el-tag type="primary">接单时间</el-tag>
            </el-badge>
            <el-badge :value="optionAAG.MS2"  type="primary">
              <el-tag type="primary">处理时间</el-tag>
            </el-badge>

        </div>
      </div>
      

      <div class="feedbackwrapper">
        <h3>用户反馈(%)</h3>
        <div class="feedback">
          <el-badge :value="optionAAG.FRATE"  type="warning">
              <el-tag type="warning">反馈率</el-tag>
            </el-badge>
            <el-badge :value="optionAAG.RRATE"  type="success">
              <el-tag type="success">解决率</el-tag>
            </el-badge>
            <el-badge :value="optionAAG.SRATE"  type="success">
              <el-tag type="success">满意度</el-tag>
            </el-badge>

        </div>
      </div>
  
    </div>

    <div class="tablewrapper">
        <el-table  :data="optionAAG.AAG002" style="width: 80%">
      <el-table-column   prop="groupName"   label="支持小组名称"  width="180"></el-table-column>
      <el-table-column   prop="CNTI"   label="工单数"  ></el-table-column>
      <el-table-column   prop="CNTA"   label="接单次数"  ></el-table-column>
      <el-table-column   prop="CNTR"   label="转单次数"  ></el-table-column>
      <el-table-column   prop="CNTC"   label="结单次数"  ></el-table-column>
      <el-table-column   prop="MS1"   label="平均接单时间(小时)" width="180" ></el-table-column>
      <el-table-column   prop="MS2"   label="平均处理时间(小时)" width="180" ></el-table-column>


    </el-table>
    </div>

    <div class="chartwrapper">
      <div ref="AAG001" id="AAG001" style="width: 900px;height:400px;"></div>
      <div ref="AAG002" id="AAG002" style="width: 10px;height:10px;"></div>
      <div ref="AAG003" id="AAG003" style="width: 10px;height:10px;"></div>
    </div>

  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "reptAAG",
  data(){
    return{
      AAG001:'',
      AAG002:'',
      AAG003:'',
      appId : '',
      corpScope: 'kcc',
      fileName  : ''
    }
  },
  computed: {
    ...mapState("rept", ["listAMS","optionAAG"]),
    ...mapState("iam", ["adminProfile"]),
    disableButton(){
      return (!this.fileName)
    }
  },
  methods: {
    ...mapActions("rept", ["OnReportOption","OnReportList","OnReportFile"]),


  async getFileList(){
    //去拿第一小组的IAM报告列表
    if (this.appId)
    {
      let isFileList = await this.OnReportList({'type':'amsapp','appId':this.appId,'corpScope':this.corpScope});
        if (!isFileList) {
          this.$router.replace({
            name: "error",
            query: {
              title: "更新数据清单时异常",
              type: "warning",
              route: "/console/reptaag",
            },
          })
        }

        if(this.listAMS.length!=0){
          this.fileName = this.listAMS[0].fileName
        }
        else{
          this.fileName = ''
        }
    }
      
  },

  async getChartOption(){
      //读对应的报表参数
      if(this.fileName)
      {
          let isChartOption = await this.OnReportOption({'type':'aag','appId':this.appId,'corpScope':this.corpScope,'fileName':this.fileName});
              if (!isChartOption) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "更新报表参数时异常",
                    type: "warning",
                    route: "/console/reptaag",
                  },
                })
              }   
            this.AAG001.setOption(this.optionAAG.AAG001)

            // this.ASG002.setOption(this.optionASG.ASG001)
            // this.ASG003.setOption(this.optionASG.ASG003)
      }
      else
      {
        this.AAG001.setOption({})
        this.AAG002.setOption({})
        this.AAG003.setOption({})
      }
      
    
    },

  async downloadFile(){
      if(this.fileName){
            let isFileDownload = await this.OnReportFile({'type':'amsapp','appId':this.appId,'corpScope':this.corpScope,'fileName':this.fileName});
              if (!isFileDownload) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "下载文件时异常",
                    type: "warning",
                    route: "/console/reptaag",
                  },
                })
              }   
      }
  },
  },

   mounted() {
    this.AAG001 = this.$echarts.init(this.$refs.AAG001)
    this.AAG002 = this.$echarts.init(this.$refs.AAG002)
    this.AAG003 = this.$echarts.init(this.$refs.AAG003)

    // 绑定第一个小组
    if (this.adminProfile.appAdmin.length!==0){
        this.appId = this.adminProfile.appAdmin[0].appId
    }

    this.getFileList()      
    // this.getChartOption()

     
    
  },
};
</script>

<style scoped lang="less">
.containerReptAAG {
    .line{
    height: 1px;
    width:90%;
    margin: 10px auto;
    background-color: #ebeced;
  }
  .selection{
    width:90%;
    margin:10px auto;
  }
  
  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .statuswrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .status{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .leadtimewrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .leadtime{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .feedbackwrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .feedback{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
  .tablewrapper{
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 30px;
  }
  .chartwrapper{
    padding:30px;
  }



}
</style>