import Vue from 'vue'
import App from './App.vue'
import * as echarts from 'echarts';
import china from '@/assets/json/china'
//注册echart 地图包
echarts.registerMap('china', china );
//  {
//     "北京": {
//         // 左上角经度
//         left: 115.25,
//         // 左上角纬度
//         top: 39.26,
//         // 经度横跨的范围
//         width: 0.21
//     },
//     "上海": {
//         left: 120.52,
//         top: 30.4,
//         width: 0.21
//     }
// }



Vue.config.productionTip=false
Vue.prototype.$echarts = echarts

import '@/plugins/element'
import router from '@/plugins/router'
import store from '@/plugins/store'




//import '@/mock/mockServe'//引入Mock,只是让他执行一次
//import 'swiper/css/swiper.css'//引入 swiper样式


new Vue({
    el:'#app',
    render: h=>h(App),
    //路由$router
    router,
    //注册仓库$store
    store,
}) 