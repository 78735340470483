<template>
  <div class="containerindtCreate">
    <div class="form">

      <h3>创建工单</h3>
      <el-divider></el-divider>
      <el-form  ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="选择应用" prop="appId">
            <el-select  v-model="form.appId" 
                        placeholder="请选择" 
                        filterable 
                        style="width:100%"
                        @change="onChangeApp">
              <el-option
                v-for="item in userProfile.appUser"
                :key="item.appId"
                :label="item.appName"
                :value="item.appId"
              >
              </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="描述问题" prop="description">
              <el-input type="textarea" 
                :autosize = "{minRows : 10}" 
                placeholder="我遇到的故障是。。。" 
                v-model="form.description">
                </el-input>
        </el-form-item>
        <el-form-item label = "接单小组" prop="groupName">
            <el-radio-group v-model="form.groupName" :disabled="radioDisabled" v-show="form.groupName">
                <el-radio  
                v-for="item in appServiceGroup" 
                :key="item.groupId"
                :label="item.groupName"></el-radio>
              </el-radio-group>
        </el-form-item>
        <el-form-item label="上传图片">
              <el-upload
                  ref="upload"
                  action="#"
                  accept="image/jpeg,image/png"
                  :on-change="onChange"
                  :on-remove="onRemove"
                  :on-preview="onPreview"
                  :on-exceed="onExceed"
                  :auto-upload="false"
                  list-type="picture-card"
                  :show-file-list="true"
                  :limit = "9"
                  multiple
                  >
                  <i class="el-icon-plus"></i>
                  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多9张</div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
        </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit" v-loading.fullscreen.lock="fullscreenLoading">立即创建</el-button>
            <el-button type="warning" @click="onReset">重新填写</el-button>
          </el-form-item>

      </el-form>

    </div>
  
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "indtCreate",
  data() {
    return {
      dialogVisible:false,
      dialogImageUrl:"",
      param:"",
      filelist:"",
      fullscreenLoading:false,
      radioDisabled:true,
      form:{
        appId:"",
        description:"",
        groupName:""
      },
        rules: {
          appId: [
            { required: true, message: '请选择应用', trigger: 'change' }
          ],
          description: [
            { required: true, message: '请描述问题', trigger: 'blur' }
          ],
          groupName: [
            { required: true, message: '请选择小组', trigger: 'blur' }
          ]
        }
     

    };
  },
  computed: {
    ...mapState("iam", ["userProfile","serviceProfile"]),
    ...mapState("indt", ["incidentId","appServiceGroup"]),


  },
  methods: {
    ...mapActions("indt", ["onIncidentAction","onIncidentQuery"]),
    async onChangeApp(appId){
         let isQuery = await this.onIncidentQuery({api:"appServiceGroup",appId})
          if (!isQuery) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "查询应用支持小组时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
          
          //Default first group
          this.form.groupName = this.appServiceGroup[0].groupName
          //check if app is part of appServcie or appAdmin
          this.radioDisabled=true
          for (let app of this.serviceProfile.appService) {
                  if(app.appId==appId)
                  {
                      this.radioDisabled=false
                      break
                  }
            }
          
          
    },
      onChange(file,filelist){
          this.filelist=filelist
      },
      onRemove(file,filelist){
        this.filelist = filelist
      },
  
      onPreview(file){
        this.dialogVisible=true
        this.dialogImageUrl = file.url
      },
      onExceed(e){
        this.$message({
          message:'最多上传9张图片',
          type:'warning',
          center:true,
          showClose:true})
      },
      async onCreate(){
            this.fullscreenLoading=true
            let appName = ""
            for (let app of this.userProfile.appUser) {
                 
                   if(app.appId==this.form.appId)
                   {
                       appName = app.appName
                       break
                   }
              }
            
            let groupId = ""
            let groupName = ""
            for (let group of this.appServiceGroup) {
                  if(group.groupName==this.form.groupName)
                  {
                      groupId = group.groupId
                      groupName = group.groupName
                      break
                  }
            }


            this.param.append("appId",this.form.appId)
            this.param.append("appName",appName)
            this.param.append("description",this.form.description)
            this.param.append("api","create")
            this.param.append("groupId",groupId)
            this.param.append("groupName",groupName)
            let isCreate = await this.onIncidentAction(this.param)
            if(isCreate){
                for (let f of this.filelist) {
                        this.param = new FormData()
                        this.param.append("api","attachment")
                        this.param.append("incidentId",this.incidentId)
                        this.param.append('files',f.raw,f.name)
                        let isAttachment = await this.onIncidentAction(this.param)
                        if(!isAttachment)
                        {
                              this.$message({
                              message:'附件未能上传成功'+f.name,
                              type:'warning',
                              // center:true,
                              showClose:true})
                        }
                }
            }
            this.fullscreenLoading=false
            if (!isCreate) {
              this.$router.replace({
                name: "error",
                query: {
                  title: "创建工单时发生异常",
                  type: "warning",
                  route: "/console/indtcreate",
                },
              })
            }
            else{
               this.$message({
                  message:'创建成功,工单号'+this.incidentId,
                  type:'success',
                  // center:true,
                  showClose:true})
                this.$router.replace({ name: "indtdetail" });
            }
            //提交表单后清除所有数据
            this.onReset()
      },
      async onSubmit(){
          this.$refs['form'].validate((valid) => {
          if (valid) {
            // this.$message('submit');
            
            this.onCreate()
            return true
           
          } else {
            return false
          }
        });
      },
      onReset() {
        this.$refs['form'].resetFields()
        this.$refs['upload'].clearFiles()
        this.param = new FormData()
        this.filelist = ""
      }
  },
  mounted() {
      this.param = new FormData()
      this.filelist = ""
  },
};
</script>

<style scoped lang="less">
.containerindtCreate {

  .form {
    width:80%;
    margin:50px auto;
  }

  h3{
    margin-top:10px;
    margin-bottom: 20px;
    font-size: 26px;
    color:#101332;
    }

}
</style>