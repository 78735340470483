export default {
    VUE:{
        host:'http://localhost:8080',
        kcc_corp:'ww07c2c1f936126304',
        kcc_agent:'1000055',
        gk_corp:'wwa19943ba2b55ea37',
        gk_agent:'1000038',
        uri:'https://ewc-askit.dev.aw.kone.cn/#/login'
    },
    LOC:{
       host:'http://localhost:5000',
       kcc_corp:'ww07c2c1f936126304',
       kcc_agent:'1000055',
       gk_corp:'wwa19943ba2b55ea37',
       gk_agent:'1000038',
       uri:'https://ewc-askit.dev.aw.kone.cn/#/login'
    },
    DEV:{
       host:'https://ewc-askit.dev.aw.kone.cn',
       kcc_corp:'ww07c2c1f936126304',
       kcc_agent:'1000055',
       gk_corp:'wwa19943ba2b55ea37',
       gk_agent:'1000038',
       uri:'https://ewc-askit.dev.aw.kone.cn/#/login'
    },
    UAT:{
      host:'https://ewc-askit.dev.aw.kone.cn',
      kcc_corp:'ww07c2c1f936126304',
      kcc_agent:'1000055',
      gk_corp:'wwa19943ba2b55ea37',
      gk_agent:'1000038',
      uri:'https://ewc-askit.dev.aw.kone.cn/#/login'
   },
    PROD:{
      host:'https://ewc-askit.kone.cn/',
      kcc_corp:'ww07c2c1f936126304',
      kcc_agent:'1000057',
      gk_corp:'wwa19943ba2b55ea37',
      gk_agent:'1000039',
      uri:'https://ewc-askit.kone.cn/#/login'
   }
  }