<template>
  <div class="containerUtilCOACH">
    <div class="selection">
      <el-descriptions title="师徒配对工具"  border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain  @click="uploadTemplate" :disabled="filelist.length==0" v-loading.fullscreen.lock="fullscreenLoading">提交配对文件</el-button>
            <el-button type="warning" plain @click="downloadTemplate">下载模板</el-button>
          </template>
        <el-descriptions-item label="选择本地文件">
              <el-upload
                  ref="upload"
                  action="#"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :on-exceed="onExceed"
                  :on-change="onChange"
                  :on-remove="onRemove"
                  :auto-upload="false"
                  list-type="text"
                  :limit = "1"
                  drag
                  >
                    <i class="el-icon-upload"></i>
                  <div class="el-upload__tip" >一次只能上传一份符合格式规范的模板文件</div>
                </el-upload>


        </el-descriptions-item>

    </el-descriptions>

    </div>

    <el-divider></el-divider>


 
     <div class="selection">
          <el-descriptions title="配对结果查询"  border :column="2" :colon="false">
          <template slot="extra">
            <el-button type="success" plain @click="onRefresh" >刷新列表</el-button>
          </template>
          </el-descriptions>

          <el-table :show-header="false" :data="MZCPairCoachList">
            <el-table-column prop="fileName">
            </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope">
        <el-button size="mini" type="info" plain @click="downloadResult(scope.$index, scope.row)">下载结果</el-button>

      </template>
            </el-table-column>
          </el-table>
     </div>


  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "utilCOACH",
  data(){
    return{
      fullscreenLoading:false,
      filelist:""



    }
  },
  computed: {
    ...mapState("util", ["MZCPairCoachList"]),

  },
  methods: {
    ...mapActions("util", ["DownloadFile","onUtilForm","onUtilJson"]),
    async downloadTemplate(){
            let isFileDownload = await this.DownloadFile({'api':'getUtilTemplate','fileName':'template_coach.xlsx'});
              if (!isFileDownload) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "下载文件时异常",
                    type: "warning",
                    route: "/console/utilcoach",
                  },
                })
              }   
      
    },
    async uploadTemplate(){
    for (let f of this.filelist) {
          this.fullscreenLoading=true
          this.param = new FormData()
          this.param.append("api","pairMZCCoach")
          this.param.append('files',f.raw,f.name)
          let isPair = await this.onUtilForm(this.param)
          this.fullscreenLoading=false

            if(isPair)
            {
                  this.$message({
                  message:f.name+'提交成功，请稍候查询处理结果',
                  type:'success',
                  // center:true,
                  showClose:true})
            }
            else{
                this.$router.replace({
                name: "error",
                query: {
                  title: "提交配对文件时发生异常",
                  type: "warning",
                  route: "/console/utilcoach",
                },
            })
          }
    }
    this.onReset()
    
    },  
    onExceed(e){
      this.$message({
      message:'一次只能上传一份文件',
      type:'warning',
      center:true,
      showClose:true})
    },
    onChange(file,filelist){
          this.filelist=filelist
      },
    onRemove(file,filelist){
        this.filelist = filelist
      },
    onReset() {
        this.$refs['upload'].clearFiles()
        this.filelist = ""
      },
    async onRefresh(){
        let isQueryList = await this.onUtilJson({api:"queryMZCPairCoachList"})
        if (!isQueryList) {
          this.$router.replace({
            name: "error",
            query: {
              title: "刷新列表时发生异常",
              type: "warning",
              route: "/console/utiltxt",
            },
          })
        }
    },
    async downloadResult(index,row){
      let fileName = row.fileName
      let isFileDownload = await this.DownloadFile({'api':'getUtilMZCPairCoach','fileName':fileName});
      if (!isFileDownload) {
        this.$router.replace({
          name: "error",
          query: {
            title: "下载文件时异常",
            type: "warning",
            route: "/console/utilcoach",
          },
        })
      }  
    }


  },
  
   mounted() {


     
    
  },
};
</script>

<style scoped lang="less">
.containerUtilCOACH {
    .line{
    height: 1px;
    width:90%;
    margin: 10px auto;
    background-color: #ebeced;
  }
  .selection{
    width:80%;
    margin:10px auto;
  }
  
  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .statuswrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .status{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .leadtimewrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .leadtime{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .feedbackwrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .feedback{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  .chartwrapper{
    padding:30px;
  }



}
</style>