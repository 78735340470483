<template>
  <div class="containerReptASG">
    <div class="selection">
      <el-descriptions title="AMS支持小组报表" :column="2" border :colon="false">
          <template slot="extra">
            <el-button type="primary" plain :disabled="disableButton" @click="getChartOption">生成图表</el-button>
            <el-button type="primary" plain :disabled="disableButton" @click="downloadFile">下载文件</el-button>
          </template>
        <el-descriptions-item label="支持小组">
          <el-select v-model="groupId"  placeholder="请选择支持小组" @change="getFileList">
            <el-option
              v-for="item in serviceProfile.groupService"
              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="文件日期">
            <el-select v-model="fileName"  placeholder="还未生成报表" >
              <el-option
                v-for="item in listAMS"
                :key="item.fileName"
                :label="item.fileName"
                :value="item.fileName"
              >
              </el-option>
            </el-select>
        </el-descriptions-item>
    </el-descriptions>

    </div>
    <el-divider></el-divider>

  
    <div class="reportwrapper">
      <div class="statuswrapper">
        <h3>工单状态(#)</h3>
          <div class="status">
            <el-badge :value="optionASG.CNT1"  type="danger">
              <el-tag type="danger">待处理</el-tag>
            </el-badge>
            <el-badge :value="optionASG.CNT2"  type="warning">
              <el-tag type="warning">处理中</el-tag>
            </el-badge>
            <el-badge :value="optionASG.CNT3+optionASG.CNT4" type="success">
              <el-tag type="success">已完成</el-tag>
            </el-badge>
          </div>
      </div>
      <div class="leadtimewrapper">
        <h3>平均响应时间（小时）</h3>
        <div class="leadtime">
          <el-badge :value="optionASG.MS12"  type="primary">
              <el-tag type="primary">派单->接单</el-tag>
            </el-badge>
            <el-badge :value="optionASG.MS23"  type="primary">
              <el-tag type="primary">接单->完成</el-tag>
            </el-badge>

        </div>
      </div>
      

      <div class="feedbackwrapper">
        <h3>用户反馈(%)</h3>
        <div class="feedback">
          <el-badge :value="optionASG.FRATE"  type="warning">
              <el-tag type="warning">反馈率</el-tag>
            </el-badge>
            <el-badge :value="optionASG.RRATE"  type="success">
              <el-tag type="success">解决率</el-tag>
            </el-badge>
            <el-badge :value="optionASG.SRATE"  type="success">
              <el-tag type="success">满意度</el-tag>
            </el-badge>

        </div>
      </div>
  
    </div>

    <div class="chartwrapper">
      <div ref="ASG001" id="ASG001" style="width: 900px;height:400px;"></div>
      <div ref="ASG002" id="ASG002" style="width: 10px;height:10px;"></div>
      <div ref="ASG003" id="ASG003" style="width: 10px;height:10px;"></div>
    </div>

  </div> 
    

 
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "reptASG",
  data(){
    return{
      ASG001:'',
      ASG002:'',
      ASG003:'',
      groupId : '',
      fileName  : ''
    }
  },
  computed: {
    ...mapState("rept", ["listAMS","optionASG"]),
    ...mapState("iam", ["serviceProfile"]),
    disableButton(){
      return (!this.fileName)
    }
  },
  methods: {
    ...mapActions("rept", ["OnReportOption","OnReportList","OnReportFile"]),


  async getFileList(){
    //去拿第一小组的IAM报告列表
    if (this.groupId)
    {
      let isFileList = await this.OnReportList({'type':'ams','groupId':this.groupId});
        if (!isFileList) {
          this.$router.replace({
            name: "error",
            query: {
              title: "更新数据清单时异常",
              type: "warning",
              route: "/console/reptasg",
            },
          })
        }

        if(this.listAMS.length!=0){
          this.fileName = this.listAMS[0].fileName
        }
        else{
          this.fileName = ''
        }
    }
      
  },

  async getChartOption(){
      //读对应的报表参数
      if(this.fileName)
      {
          let isChartOption = await this.OnReportOption({'type':'asg','groupId':this.groupId,'fileName':this.fileName});
              if (!isChartOption) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "更新报表参数时异常",
                    type: "warning",
                    route: "/console/reptasg",
                  },
                })
              }   
            this.ASG001.setOption(this.optionASG.ASG001)
            // this.ASG002.setOption(this.optionASG.ASG001)
            // this.ASG003.setOption(this.optionASG.ASG003)
      }
      else
      {
        this.ASG001.setOption({})
        this.ASG002.setOption({})
        this.ASG003.setOption({})
      }
      
    
    },

  async downloadFile(){
      if(this.groupId && this.fileName){
            let isFileDownload = await this.OnReportFile({'type':'ams','groupId':this.groupId,'fileName':this.fileName});
              if (!isFileDownload) {
                this.$router.replace({
                  name: "error",
                  query: {
                    title: "下载文件时异常",
                    type: "warning",
                    route: "/console/reptasg",
                  },
                })
              }   
      }
  },
  },

   mounted() {
    this.ASG001 = this.$echarts.init(this.$refs.ASG001)
    this.ASG002 = this.$echarts.init(this.$refs.ASG002)
    this.ASG003 = this.$echarts.init(this.$refs.ASG003)

    // 绑定第一个小组
    if (this.serviceProfile.groupService.length!==0){
        this.groupId = this.serviceProfile.groupService[0].groupId
    }

    this.getFileList()      
    // this.getChartOption()

     
    
  },
};
</script>

<style scoped lang="less">
.containerReptASG {
    .line{
    height: 1px;
    width:90%;
    margin: 10px auto;
    background-color: #ebeced;
  }
  .selection{
    width:80%;
    margin:10px auto;
  }
  
  .reportwrapper{
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .statuswrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .status{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .leadtimewrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .leadtime{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .feedbackwrapper{
      width: 300px;
      padding:10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      h3{
          color:#8e9cb2;
          margin-bottom: 15px;
      }
      .feedback{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  .chartwrapper{
    padding:30px;
  }



}
</style>