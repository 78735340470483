<template>
  <div class="ErrorContainer">
    <div class="ErrorShow">
      <el-alert
        :title="title"
        :type="type"
        :description="description"
        show-icon
        center
      >
      </el-alert>
    </div>
    <div class="ErrorResponse">
      <el-button type="warning" round @click="handleClick">返回</el-button>
    </div>

  </div>
</template>

<script>
import { getErr } from '@/utils'
export default {
  name: "Error",
  data() {
    return getErr();
  },
  methods:{
    handleClick(){
      this.$router.replace(this.route)
    }
  },
  computed:{
      description() {
      return "出错代码（" + this.errCode + "），出错信息（"+ this.errMessage +"）"
    },
  },
  props:['title','type','route']
};
</script>

<style scoped lang="less">
.ErrorContainer {
margin-top: 30px;
  display: flex;
  flex-direction: column;

  .ErroShow{

     margin-top: 30px;
    align-self: center;
  }
  .ErrorResponse{
    margin-top: 10px;
    align-self: center;
  }
}
</style>