//iam模块小仓库
import { reqOnAuthenticate,reqOnAuthorize } from '@/api'
import { setErr,setToken,getToken,removeToken,getProfile,setProfile } from '@/utils'

const state = {
    ...getToken(),
    userProfile:'',
    serviceProfile:'',
    adminProfile:'',
}
const mutations = {
    SET_TOKEN(state, value) {
        state.corp = value.corp
        state.userId = value.userId
        state.userName = value.userName
        state.deptId = value.deptId
        state.deptName = value.deptName
        state.position = value.position
        state.email = value.email
        state.mobile = value.mobile
        state.avator = value.avator
        state.token2 = value.token2
        setToken(value)
    },
    REMOVE_TOKEN(state) {
        state.corp = ''
        state.userId = ''
        state.userName = ''
        state.deptId =  ''
        state.deptName =  ''
        state.position = ''
        state.email =  ''
        state.mobile = ''
        state.avator = ''
        state.token2 = ''
        removeToken()
    },

    SET_PROFILE(state, value) {
        // console.log(value);
        state.userProfile = value.userProfile
        state.serviceProfile = value.serviceProfile
        state.adminProfile = value.adminProfile
        setProfile(value)

    },
}
const actions = {

    async onAuthenticate(context, value) {
        let result = await reqOnAuthenticate(value)
        if (result.errCode == 0) {
            context.commit('SET_TOKEN', result)
            return true
        }
        else {
            context.commit('REMOVE_TOKEN')
            setErr(result, 'iam.onAuthenticate')
            return false
        }

    },

    async onAuthorize(context) {
        let result = await reqOnAuthorize({})
        if (result.errCode == 0) {
            context.commit('SET_PROFILE', result)
            return true
        }
        else {
            context.commit('REMOVE_TOKEN')
            setErr(result, 'iam.onAuthorize')
            return false
        }

    },

   
    // jiaOdd(context,value){
    // 	console.log('actions中的jiaOdd被调用了')
    // 	if(context.state.sum % 2){
    // 		context.commit('JIA',value)
    // 	}
    // },
}
const getters = {
    // bigSum(state) {
    //     return state.sum * 10
    // }
}

export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}