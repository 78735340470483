<template>
  <div class="containerUserSGA">

    <div class="app">
      <span>app info</span>
      <el-table :data="app"  size="mini">
        <el-table-column prop="appId" label="appId" width="100"></el-table-column>
        <el-table-column prop="appName" label="appName" width="180"></el-table-column>
        <el-table-column prop="CMDB" label="CMDB" width="250"></el-table-column>
        <el-table-column prop="isAMS" label="isAMS"></el-table-column>
        <el-table-column prop="isIAM" label="isIAM"></el-table-column>
        <!-- 
         <el-table-column prop="mainCategory" label="mainCategory"></el-table-column>
         <el-table-column prop="mainCategory_sort"   label="mainCategory_sort"> </el-table-column>
         <el-table-column prop="subCategory"     label="subCategory"> </el-table-column>
         <el-table-column prop="subCategory_sort"     label="subCategory_sort">  </el-table-column> 
        -->
      </el-table>
    </div>

    <div class="app">
      <span>Service Group</span>
      <el-table :data="appSG"  size="mini">
        <el-table-column prop="groupId" label="groupId" width="100"></el-table-column>
        <el-table-column prop="groupName" label="groupName" width="180"></el-table-column>
      </el-table>
    </div>

    <div class="app">
      <span>Service Group Scope</span>
      <el-table :data="appSGS"  size="mini">
        <el-table-column prop="corp" label="corp" width="100"></el-table-column>
        <el-table-column prop="appId" label="appId" width="100"></el-table-column>
        <el-table-column prop="serviceId" label="serviceId" width="180"></el-table-column>
        <el-table-column prop="serviceName" label="serviceName" width="180"></el-table-column>
        <el-table-column prop="serviceGroup1" label="serviceGroup1" width="180"></el-table-column>
        <el-table-column prop="serviceGroup2" label="serviceGroup2" width="180"></el-table-column>
        <el-table-column prop="serviceGroup3" label="serviceGroup3" width="180"></el-table-column>
        <el-table-column prop="serviceGroup4" label="serviceGroup4" width="180"></el-table-column>
        <el-table-column prop="serviceGroup5" label="serviceGroup5" width="180"></el-table-column>
      </el-table>
    </div>

     <div class="app">
      <span>Admin Group</span>
      <el-table :data="appAG"  size="mini">
        <el-table-column prop="groupId" label="groupId" width="100"></el-table-column>
        <el-table-column prop="groupName" label="groupName" width="180"></el-table-column>
      </el-table>
    </div>

    <div class="app">
      <span>Admin Group Scope</span>
      <el-table :data="appAGS"  size="mini">
        <el-table-column prop="corp" label="corp" width="100"></el-table-column>
        <el-table-column prop="appId" label="appId" width="100"></el-table-column>
        <el-table-column prop="adminGroup1" label="adminGroup1" width="180"></el-table-column>
        <el-table-column prop="adminGroup2" label="adminGroup2" width="180"></el-table-column>
        <el-table-column prop="adminGroup3" label="adminGroup3" width="180"></el-table-column>
        <el-table-column prop="adminGroup4" label="adminGroup4" width="180"></el-table-column>
        <el-table-column prop="adminGroup5" label="adminGroup5" width="180"></el-table-column>
      </el-table>
    </div>


 
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "confAPP",
  computed: {
    ...mapState("app", [
      "app",
      "appSG",
      "appSGS",
      "appAG",
      "appAGS",
    ]),


  },
  methods: {
    ...mapActions("app", ["onReadState"]),
  },
  async mounted() {
    // if (!this.app) {}
      let isReadState = await this.onReadState({});
      if (!isReadState) {
        this.$router.replace({
          name: "error",
          query: {
            title: "获取配置信息时发生异常",
            type: "warning",
            route: "/console/confapp",
          },
        });
      }
    
  },
};
</script>

<style scoped lang="less">
.containerUserSGA {
  // height: 800px;
  // width: 100%;
  // background-color: aqua;
  color: #409eff;
  text-shadow: 5px 2px 6px rgb(160, 207, 255);
  font-family: 微软雅黑;
  .select {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 10px 10px;
    .text {
      font-size: medium;
      margin: 5px 5px;
    }
  }
  .workarea {
    margin: 10px 10px;
  }
  .app{
     margin: 10px 10px;
  }
}
</style>